import {
  Grid,
  RACButton,
  RACModalCard,
  RACSelect,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext, useState } from 'react';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import { manageCheckPayment } from '../../../api/user';
import { LoadingScreen } from '../../SharedComponents/LoadingScreen';
import { useParams } from 'react-router-dom';

export function CancellationPopup(props?: any) {
  const { classes, dropdownLoader, dropReasons, setSearchStatus } =
    useContext(ReturnCheckContext);
  const [loader, setLoader] = useState<boolean>(false);
  const routePath: any = useParams();
  const [cancelReason, setCancelReason] = useState<string>('');

  const saveCancellation = async () => {
    try {
      const transaction = routePath.transactionId
        ? routePath.transactionId
        : props?.transactionId;
      setLoader(true);
      const cancelResponse: any = await manageCheckPayment({
        transactionId: transaction,
        cancellationReason: cancelReason,
      });
      setLoader(false);
      props?.setFunction(false);
      if (
        cancelResponse &&
        cancelResponse?.status == 200 &&
        cancelResponse.data.transactionId
      ) {
        props?.verified(true);
      } else if (cancelResponse && cancelResponse?.status == 400) {
        props?.forSearch ? setSearchStatus(400) : props?.badRequest(true);
      } else {
        props?.forSearch ? setSearchStatus(500) : props?.error(true);
      }
    } catch (e: any) {
      props?.setFunction(false);
      props?.badRequest(false);
      props?.forSearch ? setSearchStatus(500) : props?.error(true);
    }
  };

  return (
    <>
      {loader ? <LoadingScreen /> : <></>}

      <RACModalCard
        isOpen={true}
        closeIcon={true}
        onClose={() => {
          props?.setFunction(false);
        }}
        title="Returned Check Cancellation"
        maxWidth={'xs'}
        borderRadius={'15px'}
      >
        <Grid container md={12}>
          <Grid item md={12} className={classes.popupInfo}>
            <Typography variant="h6">
              Why is this returned check being cancelled?
            </Typography>
          </Grid>
          <Grid item md={7} className={classes.popupDropdown}>
            <RACSelect
              defaultValue={cancelReason}
              onChange={(e: any) => {
                setCancelReason(e.target.value);
              }}
              options={dropReasons}
              loading={dropdownLoader}
            />
          </Grid>
        </Grid>

        <Grid className={classes.popupSave}>
          <Grid className={`${classes.floatRight} `}>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                saveCancellation();
              }}
              disabled={cancelReason === '' ? true : false}
            >
              Save
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="outlined"
              color="secondary"
              className={`${classes.mr3} ${classes.popupCancel}`}
              onClick={() => {
                props?.setFunction(false);
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </RACModalCard>
    </>
  );
}
