import { Grid, RACModalCard, CircularProgress } from '@rentacenter/racstrap';
import React from 'react';
import { ReturnCheckStyles } from '../../JsStyles/ReturnCheckStyles';

export function LoadingScreen() {
  const classes = ReturnCheckStyles();
  return (
    <>
      <RACModalCard
        className={classes.loaderBackground}
        isOpen={true}
        closeIcon={false}
        maxWidth={'xs'}
        borderRadius={0}
      >
        {
          <Grid>
            <CircularProgress />
          </Grid>
        }
      </RACModalCard>
    </>
  );
}
