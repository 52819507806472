import { Card, CardContent, Grid } from '@rentacenter/racstrap';
import React from 'react';
import { PaymentDetails } from './PaymentDetails';
import { CustomerInfo } from './CustomerInfo';
import { CheckInfo } from './CheckInfo';
import { ReturnCheckStyles } from '../../../JsStyles/ReturnCheckStyles';

export function DetailsCard() {
  const classes = ReturnCheckStyles();

  return (
    <>
      <Card className={`${classes.pt15} ${classes.cardBorder}`}>
        <CardContent>
          <Grid container md={12}>
            <Grid item md={4}>
              <CustomerInfo />
            </Grid>

            <Grid item md={4}>
              <CheckInfo />
            </Grid>

            <Grid item md={4}>
              <PaymentDetails />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
