import { Grid, Typography } from '@rentacenter/racstrap';
import { useParams } from 'react-router-dom';
import React from 'react';
import { ReturnCheckStyles } from '../../../JsStyles/ReturnCheckStyles';
import { getDetailsHeader } from '../../../utils/ReturnCheckUtils';
export function DetailsHeader() {
  const routePath: any = useParams();
  const classes = ReturnCheckStyles();
  return (
    <>
      <Grid container md={12} className={classes.pb1}>
        <Grid item md={11} sm={10} xs={9} lg={11}>
          <Typography
            variant="h5"
            className={`${classes.title} ${classes.pt1}`}
          >
            {getDetailsHeader(routePath?.category)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
