import { Card, CardContent, Grid } from '@rentacenter/racstrap';
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CreditCardContext } from '../CreditcardContext';
import { params } from '../../../interface';
import CCDetailHeader from './CCDetailHeader';
import { CustomerDetails } from './CustomerDetails';
import { CCDetailsInputs } from './Inputfields';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';

export function CCDetailsCard() {
  const params = useParams<params>();
  const classes = EmilyGlobalStyles();
  const {
    setTransId,
    //transId
  } = useContext(CreditCardContext);

  async function renderDetailsPage() {
    setTransId(params.transactionId);
    // const detailsResponse = await getCheckDetails(routePath.transactionId);
  }
  useEffect(() => {
    renderDetailsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Grid container md={12}>
            <Grid item md={4}>
              <CustomerDetails />
            </Grid>

            <Grid item md={4}>
              <CCDetailHeader />
            </Grid>

            <Grid item md={4}>
              <CCDetailsInputs />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
