export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}

export const CHECK_STATUSES = [
  { label: 'All', value: 'ALL' },
  { label: 'Returned Check', value: 'RCHK' },
  { label: 'Check Payment', value: 'CHK' },
];
export const ONLY_NUMBERS = /^[0-9]+$/;
export const ONLY_LETTERS = /^[A-Za-z,' ']+$/;

export const CANCEL_REASONS = ['HERR', 'OTHER'];
export const ADD_REASONS = [
  'NOTSUFFNDS',
  'UNCLFNDHLD',
  'STOPPAYMNT',
  'CLOSEDACCT',
  'UNTLOCACCT',
  'FROZENACCT',
  'STALEDATED',
  'POSTDATED',
  'ENDORSMISG',
  'ENDORSIRRG',
  'SIGMISSIG',
  'SIGIRREGLR',
  'NOCASHITEM',
  'ALTRDITEM',
  'UNBLETOPRS',
  'ITMEXCDDLR',
  'NOTAUTHRZD',
  'BRANCHSOLD',
  'REFTOMAKER',
  'STOPAYSPCT',
  'UNUSBLIMAG',
  'IMGFAILCHK',
  'CANNTDTACC',
];

export const DEFAULT_CHECK_LIMIT = 25;
