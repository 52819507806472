import React, { useContext } from 'react';
import { CreditCardContext } from '../CreditcardContext';
import { formatDate, categoryType } from '../CreditcardSearch/Commonfunctions';
import { Grid, Typography } from '@rentacenter/racstrap';
import {
  maskCreditCard,
  formatePayment,
} from '../CreditcardSearch/Commonfunctions';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export default function CCDetailHeader() {
  const { customer, loader } = useContext(CreditCardContext);
  const classes = EmilyGlobalStyles();
  const getLoader = () => (
    <Typography style={{ width: '75%' }}>
      <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
    </Typography>
  );
  const cardDetails = () => (
    <>
      <Grid container md={12} className={classes.colr}>
        <Grid item md={6}>
          <Typography>Category</Typography>
        </Grid>
        <Grid item md={6} className={classes.formValue}>
          {loader ? (
            getLoader()
          ) : (
            <Typography>
              {customer?.category ? categoryType(customer.category) : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Grid container>
        <Grid container md={12} style={{ marginBottom: '1rem' }}>
          <Typography variant="h5">Credit Card Information</Typography>
        </Grid>

        {cardDetails()}
        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography>Payment Date</Typography>
          </Grid>
          <Grid item md={6}>
            {loader ? (
              getLoader()
            ) : (
              <Typography className={classes.formValue}>
                {customer?.paymentDate ? formatDate(customer.paymentDate) : '-'}
              </Typography>
            )}
          </Grid>
          <Grid container md={12} className={classes.colr}>
            <Grid item md={6}>
              <Typography>Card Type</Typography>
            </Grid>
            <Grid item md={6}>
              {loader ? (
                getLoader()
              ) : (
                <Typography
                  className={`${classes.formValue} ${classes.pr3}`}
                  noWrap
                >
                  {customer?.cardType ? customer.cardType : '-'}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container md={12} className={classes.colr}>
            <Grid item md={6}>
              <Typography>Credit Card #</Typography>
            </Grid>
            <Grid item md={6}>
              {loader ? (
                getLoader()
              ) : (
                <Typography className={classes.formValue}>
                  {customer?.creditCardNum
                    ? maskCreditCard(customer.creditCardNum)
                    : '-'}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container md={12} className={classes.colr}>
            <Grid item md={6}>
              <Typography>Payment Amount</Typography>
            </Grid>
            <Grid item md={6}>
              {loader ? (
                getLoader()
              ) : (
                <Typography className={classes.formValue}>
                  {customer?.paymentAmount
                    ? formatePayment(customer.paymentAmount)
                    : '-'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container md={12} className={classes.colr}>
            <Grid item md={6}>
              <Typography>Authorization #</Typography>
            </Grid>
            <Grid item md={6}>
              {loader ? (
                getLoader()
              ) : (
                <Typography className={classes.formValue}>
                  {customer?.authorizationNumber
                    ? customer.authorizationNumber
                    : '-'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
