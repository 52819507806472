/* eslint-disable prettier/prettier */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const ReturnCheckStyles = () => {
    const useClasses = makeStyles((theme) => ({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '10px',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgb(189, 189, 196)',
                borderRadius: '20px',
                border: '3px solid rgb(206, 204, 201)',
            },
            '*::-webkit-scrollbar-track': {
                background: 'rgb(245, 243, 239)',
            },
        },

        currencyField: {
            '& input': {
                textAlign: 'end',

            }
        },

        dollarCss: {
            padding: '7px'
        },

        buttonPadding: {
            padding: "10px 25px"
        },

        disableColor: {
            color: '#7FA6FF'
        },

        newCard: {
            marginTop: '1rem'
        },

        whiteBgColor: {
            backgroundColor: 'white'
        },

        actionSize: {
            cursor: 'pointer', height: '22px'
        },

        actionButtons: {
            padding: '2px 7px', marginRight: '8px'
        },

        cancelButtonColor: {
            padding: '3px 8px',
            color: '#E47363',
            border: '0.5px solid #FFD9D9',
            backgroundColor: '#FFFAFA'
        },

        cursor: {
            cursor: 'pointer'
        },

        infiniteLoader: {
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '16px',
            color: 'black',
            margin: '30px 0px',
        },

        loaderBackground: {
            textAlign: 'center',
            '& div': {
                '& div': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            },
        },

        infoPopup: {
            '& div': {
                '& div': {
                    borderRadius: '20px',
                    maxWidth: "350px",

                }
            }
        },

        dollarSymbol: {
            height: "32px"
        },

        fixedFooter: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            padding: '1rem',

        },

        errorbutton: {
            backgroundColor: "#c62828",
            '&:hover': {
                backgroundColor: "#c62828"
            }
        },

        racpadLink: {
            color: '#2179FE',
            fontSize: theme.typography.pxToRem(14),
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
        },

        formValue: {
            fontFamily: 'OpenSans-semibold',
            letterSpacing: 'normal !important',
            color: 'darkslateblue',
            fontSize: '14px',
        },

        centerAlign: {
            textAlign: 'center',
        },

        textRight: {
            textAlign: 'right',
        },

        justifyCenter: {
            alignContent: 'center',
        },

        cardBorder: {
            borderRadius: '15px'
        },
        subTitle: {
            marginBottom: '1.5rem'
        },

        cardValue: {
            margin: '1rem 0rem'
        },

        paymentValues: {
            margin: '0.5rem 0rem'
        },
        
        returnPayment: {
            margin: '0.8rem 0rem'
        },
        
        floatLeft: {
            float: 'left',
        },

        floatRight: {
            float: 'right',
        },
        
        title: {
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontWeight: "bold",
            fontSize: '18px',
            marginBottom: theme.typography.pxToRem(10),
            marginTop: '1rem'
        },
        headerButton: {
            display: 'flex',
            justifyContent: 'end',
            marginTop: '0.2rem',
        },

        textFixHead: {

            '& th': {

                top: 0,
                zIndex: 10,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-bold',
                padding: '0.5rem 0.2rem',
                fontSize: '15px',
                fontWeight: '400',
                color: '#111111',
            },

            '& tbody': {
                '& tr': {

                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                },
            },
        },
        errorInfoCard: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '200px',
            borderRadius: '15px'
        },
        
        w75: {
            width: '75%'
        },
        
        headerStyle: {
            display: 'flex',
            justifyContent: 'end',
            marginTop: '0.2rem',
        },
        popupSave: {
            marginTop: '30px', 
            padding: '0px 20px'
        },
        popupCancel:{
            padding: '10px 25px'
        },
        popupDropdown:{
            padding: '10px 12px'
        },
        popupInfo:{
            padding: '0px 12px'
        },
        p1: {
            padding: '1rem'
        },

        p5: {
            padding: '10px'
        },
        p17: {
            paddingLeft: '17px'
        },
        pt1: {
            paddingTop: '1rem'
        },
        pt15: {
            paddingTop: '15px'
        },
        
        pb1: {
            paddingBottom: '1rem'
        },
        
        pb4: {
            paddingBottom: theme.typography.pxToRem(24),
        },

        mb16: {
            marginBottom: '0px'
        },

        mtb1: {
            marginTop: '5px',
            marginBottom: '16px'
        },

        mbr2: {
            marginBottom: '2rem'
        },

        mtp5: {
            marginTop: '5px'
        },

        mt1: {
            marginTop: theme.typography.pxToRem(4),
        },

        mt2: {
            marginTop: theme.typography.pxToRem(8),
        },

        mt3: {
            marginTop: theme.typography.pxToRem(16),
        },
        
        mlr1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
        },

        mr1: {
            marginRight: theme.typography.pxToRem(4),
        },
        mr2: {
            marginRight: theme.typography.pxToRem(8),
        },
        mr3: {
            marginRight: theme.typography.pxToRem(16),
        },

        m05: {
            margin: '0.5rem 0rem'
        },
        m08: {
            margin: '0.8rem 0rem'
        },
        mt5: {
            marginTop: '5px'
        },
        heightAuto: {
            height: 'auto'
        } 
    }));
    return useClasses();
};
