import React, { createContext, useState } from 'react';
import { EmilyGlobalStyles } from '../../Styles/emilystyles';
import { handleCharge, addCharge } from '../../interface';
import {
  formateditDate,
  formatePayment,
  validatesearch,
} from './CreditcardSearch/Commonfunctions';
export const CreditCardContext = createContext<any>('');
export default function CreditCardProvider(props: any) {
  const classes = EmilyGlobalStyles();
  const [handleCharge, sethandleCharge] = useState<handleCharge>({
    storeNumber: '',
    lastFourDigit: '',
    authorizationNum: '',
    fromDate: '',
    toDate: '',
    amount: '',
    status: 'ALL',
    limit: 20,
    offset: 0,
  });
  const [offset, setOffset] = useState<number>(20);
  const validationData: boolean = validatesearch(handleCharge);
  const clearButtonDisable: boolean =
    (handleCharge?.storeNumber?.trim()?.length ?? 0) > 4 ||
    handleCharge?.lastFourDigit?.trim() != '' ||
    handleCharge?.authorizationNum?.trim() != '' ||
    handleCharge?.fromDate != '' ||
    handleCharge?.toDate != '' ||
    handleCharge?.amount?.trim() != '';
  handleCharge.status != 'ALL';
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [filterDisable, setFilterDisable] = useState<boolean>(true);
  const [dropdownLoader, setDropdownLoader] = useState<boolean>(true);
  //dropdown
  const [status, setStatus] = useState([
    { label: 'All', value: 'ALL' },
    { label: 'Credit Card Payment', value: 'CC' },
    { label: 'Chargeback', value: 'CHB' },
  ]);
  const [canceldrop, setcanceldrop] = useState([
    { label: 'Human Error', value: '0' },
    { label: 'Reversal from bank', value: '1' },
  ]);
  const [transId, setTransId] = useState<string | number>();
  //hide & show grid and sort
  const [displaySearch, setDisplaySearch] = useState<boolean>(true);
  const [displaySearchResults, setDisplaySearchResults] =
    useState<boolean>(false);
  const [boolean, setBoolean] = useState<boolean>(true);
  const [isSort, setisSort] = useState<boolean>(true);
  //to open cancel popup
  const [Cancel, setCancel] = useState<boolean>(false);
  const [Save, setSave] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  //Errormsg
  const [dropDownloading, setDropDownloading] = useState<boolean>();
  const [addReasons, setAddReasons] = useState<any>([
    { label: 'Select', value: '' },
  ]);
  const [dropReasons, setDropReasons] = useState<any>([
    { label: 'Select', value: '' },
  ]);
  const [pageDisplay, setPageDisplay] = useState<number>(200);
  const [managepageDisplay, setmanagePageDisplay] = useState<number>(200);
  const [ErrPopup, setErrPopup] = useState<boolean>(false);
  const [manageErrMsg, setmanageErrMsg] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');
  const [viewableRecords, setViewableRecords] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [footer, setFooter] = useState<boolean>(false);
  //const validationcancel = handlecancel.reason.trim() !== '0';
  const [view, setView] = useState<boolean>(false);
  const [fetchDataLoader, setfetchDataLoader] = useState<boolean>(false);
  const [detailsPageDisplay, setdetailsPageDisplay] = useState<number>(200);
  const [customer, setcustomer] = useState<any>();
  const [addCharge, setaddCharge] = useState<addCharge>({
    chargebackDate: customer?.chargebackDate
      ? formateditDate(customer?.chargebackDate)
      : '',
    chargebackReason: customer?.chargebackReason
      ? customer?.chargebackReason
      : '',
    chargebackAmount: customer?.chargebackAmount
      ? formatePayment(customer?.chargebackAmount)
      : '0.00',
  });
  const validation =
    addCharge.chargebackDate != '' &&
    addCharge.chargebackReason != '0' &&
    addCharge.chargebackAmount != '';
  const [Reason, setReason] = useState([
    { label: 'Select', value: '0' },
    { label: 'V3-pre-compliance', value: '1' },
  ]);
  const [errpopup, setErrpopup] = useState(false);
  const [saveCheck, setSaveCheck] = useState<boolean>(false);
  const [errObj, seterrObj] = useState({
    chargebackDate: '',
    chargebackReason: '',
    chargebackAmount: '',
  });
  const [render, setRender] = useState<boolean>(false);
  const [DetailsLoader, setDetailsLoader] = useState<boolean>(false);
  const [mngMsg, setmngMsg] = useState('');
  const [updateData, setupdateDate] = useState({
    transactionId: '',
    chargeBackDate: '',
    chargeBackReason: '',
    chargeBackAmount: '',
  });
  const ONLY_NUMBERS = /^[0-9]+$/;
  return (
    <div>
      <CreditCardContext.Provider
        value={{
          classes,
          handleCharge,
          sethandleCharge,
          status,
          setStatus,
          canceldrop,
          setcanceldrop,
          displaySearch,
          setDisplaySearch,
          displaySearchResults,
          setDisplaySearchResults,
          boolean,
          setBoolean,
          isSort,
          setisSort,
          Cancel,
          setCancel,
          Save,
          setSave,
          dropDownloading,
          setDropDownloading,
          pageDisplay,
          setPageDisplay,
          ErrPopup,
          setErrPopup,
          manageErrMsg,
          setmanageErrMsg,
          viewableRecords,
          setViewableRecords,
          validationData,
          clearButtonDisable,
          offset,
          setOffset,
          loader,
          setLoader,
          filterDisable,
          setFilterDisable,
          errMsg,
          setErrMsg,
          view,
          setView,
          customer,
          setcustomer,
          addCharge,
          setaddCharge,
          validation,
          Reason,
          setReason,
          errObj,
          seterrObj,
          mngMsg,
          setmngMsg,
          ONLY_NUMBERS,
          transId,
          setTransId,
          errpopup,
          setErrpopup,
          detailsPageDisplay,
          setdetailsPageDisplay,
          managepageDisplay,
          setmanagePageDisplay,
          fetchDataLoader,
          setfetchDataLoader,
          DetailsLoader,
          setDetailsLoader,
          render,
          setRender,
          cancelReason,
          setCancelReason,
          saveCheck,
          setSaveCheck,
          dropdownLoader,
          setDropdownLoader,
          addReasons,
          setAddReasons,
          dropReasons,
          setDropReasons,
          updateData,
          setupdateDate,
          hasMore,
          setHasMore,
          footer,
          setFooter,
        }}
      >
        {props.children}
      </CreditCardContext.Provider>
    </div>
  );
}
