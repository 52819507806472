import React, { useContext, useEffect } from 'react';
import { CreditCardContext } from '../CreditcardContext';
import { useParams } from 'react-router-dom';
import { CCDetailsCard } from './CCDetailsCard';
import { CCFooter } from './CCFooter';
import { getCCPaymentDetails } from '../../../api/user';
import { getCCDropdowns } from '../CreditcardSearch/Commonfunctions';
import { DetailsHeader } from './CCDetailsHeading';
import { formateditDate } from '../CreditcardSearch/Commonfunctions';
import { params } from '../../../interface';
import { Grid } from '@rentacenter/racstrap';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import SomethingWentWrongPopUp from '../Somethingwentwrong';
export default function CCDetails() {
  const classes = EmilyGlobalStyles();
  const {
    setcustomer,
    setLoader,
    setmngMsg,
    errpopup,
    mngMsg,
    setErrpopup,
    detailsPageDisplay,
    setdetailsPageDisplay,
    setDropdownLoader,
    setAddReasons,
    setDropReasons,
    addReasons,
    dropReasons,
    setupdateDate,
  } = useContext(CreditCardContext);
  useEffect(() => {
    renderCCDetails();
    if (addReasons.length == 1 && dropReasons.length == 1) {
      bindDropDowns();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const params: params = useParams();
  //setTransId(params.transactionId);
  const renderCCDetails = async () => {
    setLoader(true);
    const ccDetailsResponse = await getCCPaymentDetails(params.transactionId);
    if (
      ccDetailsResponse &&
      ccDetailsResponse?.status == 200 &&
      ccDetailsResponse?.data?.cCPaymentDetail.length != 0
    ) {
      setcustomer(ccDetailsResponse.data?.cCPaymentDetail[0]);
      setdetailsPageDisplay(200), setLoader(false);
      setupdateDate({
        transactionId: params?.transactionId,
        chargeBackDate: ccDetailsResponse?.data?.cCPaymentDetail[0]
          ?.chargebackDate
          ? formateditDate(
              ccDetailsResponse?.data?.cCPaymentDetail[0]?.chargebackDate
            )
          : '',
        chargeBackReason:
          params.category != 'addchargeback'
            ? ccDetailsResponse?.data?.cCPaymentDetail[0]?.chargebackReason
              ? ccDetailsResponse?.data?.cCPaymentDetail[0]?.chargebackReason
              : ''
            : '',
        chargeBackAmount: ccDetailsResponse?.data?.cCPaymentDetail[0]
          ?.chargebackAmount
          ? ccDetailsResponse?.data?.cCPaymentDetail[0]?.chargebackAmount.toString()
          : '0.00',
      });
    } else if (ccDetailsResponse?.data?.cCPaymentDetail.length == 0) {
      setdetailsPageDisplay(204);
      setLoader(false);
      setmngMsg('No Records found');
      setErrpopup(true);
    } else if (ccDetailsResponse?.status == 400) {
      setdetailsPageDisplay(400);
      setLoader(false);
      setmngMsg('Bad Request');
      setErrpopup(true);
    } else {
      setdetailsPageDisplay(500);
      setLoader(false);
      setmngMsg('Something went wrong');
      setErrpopup(true);
    }
  };
  const bindDropDowns = async () => {
    setDropdownLoader(true);
    const dropdownvalues = await getCCDropdowns();
    setAddReasons(dropdownvalues?.returnReasons);
    setDropReasons(dropdownvalues?.cancelReasons);
    setDropdownLoader(false);
  };
  return (
    <>
      <Grid container md={12} className={`${classes.pagePadding1}`}>
        <DetailsHeader />
        <CCDetailsCard />
        <CCFooter />
      </Grid>
      {detailsPageDisplay != 200 ? (
        <SomethingWentWrongPopUp tab={errpopup} msg={mngMsg} />
      ) : (
        <></>
      )}
      {/* {loader ? <LoadingScreen /> : <></>} */}
    </>
  );
}
