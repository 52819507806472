import { Card, CardContent, Grid, Typography } from '@rentacenter/racstrap';
import { ReactComponent as ErrorIcon } from '../../Assets/Icons/Error.svg';
import { ReactComponent as NoRecordsIcon } from '../../Assets/Icons/No records.svg';
import { ReturnCheckStyles } from '../../JsStyles/ReturnCheckStyles';
import React from 'react';

export function ErrorInfoCard(props?: any) {
  const classes = ReturnCheckStyles();
  return (
    <>
      <Card className={classes.errorInfoCard}>
        <CardContent>
          <Grid className={classes.centerAlign}>
            {props?.status == 204 ? <NoRecordsIcon /> : <ErrorIcon />}
          </Grid>
          <Grid className={classes.pt15}>
            <Typography>
              {props?.status == 204
                ? 'No Records Found'
                : props?.status == 400
                ? 'Bad Request'
                : 'Something went wrong'}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
