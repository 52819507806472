import { getCheckPaymentSummary, getDropDownData } from '../api/user';
import { ADD_REASONS, CANCEL_REASONS } from '../constants/constants';

export async function getCheckDropdowns() {
  try {
    const reasonsResponse = await getDropDownData();
    const cancelReason = [{ label: 'Select', value: '' }];
    const addReason = [{ label: 'Select', value: '' }];

    if (
      reasonsResponse &&
      reasonsResponse.status == 200 &&
      reasonsResponse.data.AddCancelReason &&
      reasonsResponse.data.AddReturnReason
    ) {
      reasonsResponse.data.AddCancelReason.forEach((obj: any) => {
        if (obj.active == 'Y' && CANCEL_REASONS.includes(obj.referenceCode)) {
          cancelReason.push({
            label: obj.description,
            value: obj.referenceCode,
          });
        }
      });

      reasonsResponse.data.AddReturnReason.forEach((obj: any) => {
        if (obj.active == 'Y' && ADD_REASONS.includes(obj.referenceCode)) {
          addReason.push({ label: obj.description, value: obj.referenceCode });
        }
      });
    }
    return { returnReasons: addReason, cancelReasons: cancelReason };
  } catch (e: any) {
    return {
      returnReasons: [{ label: 'Select', value: '' }],
      cancelReasons: [{ label: 'Select', value: '' }],
    };
  }
}

export function formatDate(dateString: any) {
  const date = new Date(dateString);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

export function formatYearby(dateString: any) {
  const date = new Date(dateString);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function formatCurrency(amount: string | number) {
  const sanitizedAmount =
    typeof amount === 'string' ? amount.replace(/,/g, '') : amount;
  const parsedAmount = Number(sanitizedAmount);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const formattedValue = formatter.format(parsedAmount);
  return formattedValue.replace('$', '$ ');
}

export function sortPaymentDate(Array: any[], order: string) {
  const dateSort = (a: any, b: any) => {
    const dateA: any = new Date(a.paymentDate);
    const dateB: any = new Date(b.paymentDate);
    if (order == 'asc') {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };
  Array.sort(dateSort);
}

export async function searchChecks(payload: any) {
  try {
    let status = 0;
    let result: any = [];

    const searchResponse: any = await getCheckPaymentSummary(payload);

    if (
      searchResponse &&
      searchResponse?.status == 200 &&
      searchResponse?.data
    ) {
      if (
        searchResponse?.data?.length == 0 ||
        searchResponse?.data?.response?.length == 0
      ) {
        status = 204;
      } else {
        status = 200;
        result = searchResponse?.data;
      }
    } else if (searchResponse && searchResponse?.data?.value?.code == 400) {
      status = 400;
    } else {
      status = 500;
    }

    return { status: status, response: result };
  } catch (err: any) {
    return { status: 500, response: [] };
  }
}

export function categoryType(refCode: string) {
  switch (refCode) {
    case 'RETURNEDCHECK': {
      return 'Returned Check';
    }
    case 'CHECKPAYMENT': {
      return 'Check Payment';
    }
    case 'PAIDONRETURNEDCHECK': {
      return 'Paid Returned Check';
    }
  }
}

export function getDetailsHeader(category: string) {
  switch (category) {
    case 'add': {
      return 'Add Returned Check';
    }
    case 'edit': {
      return 'Edit Returned Check';
    }
    case 'paidonreturncheck': {
      return 'View Paid Returned Check';
    }
    case 'paidinreturncheck': {
      return 'Add Returned Check';
    }
  }
}

export function validateFields(payload: any) {
  const condition: boolean[] = [];
  if (payload.storeNumber !== '') {
    condition.push(payload.storeNumber.trim().length == 5);
  }
  if (
    payload.amount.trim() !== '' ||
    payload.checkNumber.trim() !== '' ||
    payload.fromDate.trim() !== '' ||
    payload.toDate.trim() !== '' ||
    payload.checkWrittenBy.trim() !== ''
  ) {
    condition.push(true);
  }

  return condition.length > 0 && condition.every((condition) => condition);
}
