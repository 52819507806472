import React, { useContext } from 'react';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import { Grid, Typography } from '@rentacenter/racstrap';
import Skeleton from 'react-loading-skeleton';
import {
  categoryType,
  formatCurrency,
  formatDate,
} from '../../../utils/ReturnCheckUtils';

export function CheckInfo() {
  const { classes, checkDetails, loadDetails } = useContext(ReturnCheckContext);

  const getLoader = () => (
    <Typography className={classes.w75}>
      <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
    </Typography>
  );

  return (
    <>
      <Grid container md={12} className={classes.subTitle}>
        <Typography variant="h5">Check Information</Typography>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Category</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.category
                ? categoryType(checkDetails?.category)
                : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Check #</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography className={`${classes.pr3}`} variant="body2">
              {checkDetails?.checkNumber ? checkDetails?.checkNumber : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Check Written By</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.checkWrittenBy
                ? checkDetails?.checkWrittenBy
                : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Payment Amount</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.amount
                ? formatCurrency(checkDetails?.amount)
                : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Payment Date</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.paymentDate
                ? formatDate(checkDetails?.paymentDate)
                : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}
