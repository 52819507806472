import { Grid, RACButton } from '@rentacenter/racstrap';
import { CreditCardContext } from '../CreditcardContext';
import { useHistory, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
//import { ReactComponent as Tick } from '../../../Assets/success-icon.svg';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import { params } from '../../../interface';
import { manageCCDetails } from '../../../api/user';
import BadRequestPopup from '../../CreditcardChargeback/BadRequest';
import VerifiedPopup from '../VerifiedPopup';
import ErrorPopup from '../../SharedComponents/ErrorPopup';
import { LoadingScreen } from '../../SharedComponents/LoadingScreen';
import { Cancelpopup } from '../Cancelpopup';
import { formatYearby } from '../../../utils/ReturnCheckUtils';
export function CCFooter() {
  const history = useHistory();
  const params: params = useParams();
  const classes = EmilyGlobalStyles();
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const [badRequest, setBadRequest] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [ccCancelled, setccCancelled] = useState<boolean>(false);
  const [CCAdded, setCCAdded] = useState(false);
  const [CHBAdded, setCHBAdded] = useState(false);
  const {
    transId,
    DetailsLoader,
    saveCheck,
    setDetailsLoader,
    setCancelReason,
    updateData,
  } = useContext(CreditCardContext);
  const fieldsValidator =
    saveCheck &&
    updateData.chargeBackDate !== '' &&
    updateData.chargeBackAmount !== '0.00' &&
    updateData.chargeBackReason !== '';
  async function addUpdatechargeback() {
    try {
      setDetailsLoader(true);
      const payload = {
        transactionId: transId,
        chargeBackDate: updateData.chargeBackDate
          ? formatYearby(updateData.chargeBackDate)
          : '',
        chargeBackReason: updateData.chargeBackReason
          ? updateData.chargeBackReason
          : '',
        chargeBackAmount: updateData.chargeBackAmount
          ? updateData.chargeBackAmount
          : '',
      };
      const updateResponse: any = await manageCCDetails(payload);
      setDetailsLoader(false);
      if (
        updateResponse?.status == 200 &&
        updateResponse?.data?.value?.transactionId
      ) {
        {
          params.category == 'addchargeback'
            ? setCCAdded(true)
            : setCHBAdded(true);
        }
      } else if (
        updateResponse &&
        ((updateResponse?.status == 200 &&
          updateResponse?.data?.value?.code == 400) ||
          updateResponse?.status == 400)
      ) {
        setBadRequest(true);
      } else {
        setShowError(true);
      }
    } catch (e: any) {
      setShowError(true);
    }
  }
  return (
    <>
      <Grid className={`${classes.fixedFooter}`}>
        <Grid className={classes.floatLeft}>
          <RACButton
            color="primary"
            variant="outlined"
            size="large"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </RACButton>
        </Grid>
        {params.category != 'viewchargeback' ? (
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              disabled={fieldsValidator ? false : true}
              onClick={() => {
                addUpdatechargeback();
              }}
            >
              Save
            </RACButton>
          </Grid>
        ) : (
          <></>
        )}
        {params.category == 'editchargeback' ? (
          <Grid className={`${classes.floatRight} ${classes.mr3}`}>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.errorbutton}`}
              onClick={() => {
                setCancelPopup(true);
                setCancelReason('');
              }}
            >
              Cancel Chargeback
            </RACButton>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {DetailsLoader ? <LoadingScreen /> : <></>}
      {showError ? <ErrorPopup setFunction={setShowError} /> : <></>}
      {badRequest ? <BadRequestPopup /> : <></>}
      {ccCancelled ? (
        <VerifiedPopup
          message={'Successfully Cancelled Chargeback'}
          isCancel={true}
          // setFunction={setccCancelled}
        />
      ) : (
        <></>
      )}
      {CCAdded ? (
        <VerifiedPopup
          message={'Chargeback Added  Successfully'}
          // setFunction={setCCAdded}
        />
      ) : CHBAdded ? (
        <VerifiedPopup
          message={' Successfully Updated Chargeback '}
          // setFunction={setCHBAdded}
        />
      ) : (
        <></>
      )}
      {cancelPopup ? (
        <Cancelpopup
          setFunction={setCancelPopup}
          badRequest={setBadRequest}
          error={setShowError}
          verified={setccCancelled}
        />
      ) : (
        <></>
      )}
    </>
  );
}
