import React, { useContext } from 'react';
import { FilterWidget } from './SearchComponents/FilterWidget';
import { SearchHeader } from './SearchComponents/SearchHeader';
import { ReturnCheckContext } from '../../context/ReturnCheck/ReturnCheckContext';
import { ResultWidget } from './SearchComponents/ResultWidget';
import { LoaderCard } from '../SharedComponents/LoaderCard';
import { ErrorInfoCard } from '../SharedComponents/ErrorInfoCard';

export function CheckSearch() {
  const { classes, showFilter, searchStatus, filteredChecks } =
    useContext(ReturnCheckContext);
  return (
    <div className={classes.p1}>
      <SearchHeader />

      {showFilter ? (
        <div className={classes.newCard}>
          <FilterWidget />
        </div>
      ) : (
        <></>
      )}

      {searchStatus == 50 ? (
        <div className={classes.newCard}>
          <LoaderCard />
        </div>
      ) : (
        <></>
      )}

      {searchStatus == 200 && filteredChecks?.length > 0 ? (
        <div className={classes.newCard}>
          <ResultWidget />
        </div>
      ) : (
        <></>
      )}

      {searchStatus !== 200 && searchStatus !== 0 && searchStatus !== 50 ? (
        <div className={classes.newCard}>
          <ErrorInfoCard status={searchStatus} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
