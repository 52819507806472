import { Card, CardContent, CircularProgress } from '@rentacenter/racstrap';
import { ReturnCheckStyles } from '../../JsStyles/ReturnCheckStyles';
import React from 'react';

export function LoaderCard() {
  const classes = ReturnCheckStyles();
  return (
    <>
      <Card className={classes.errorInfoCard}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    </>
  );
}
