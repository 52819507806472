import { Grid, RACButton } from '@rentacenter/racstrap';

import { useHistory, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import { manageCheckPayment } from '../../../api/user';
import VerifiedPopup from '../../SharedComponents/VerifiedPopup';
import { CancellationPopup } from '../SearchComponents/CancellationPopup';
import { LoadingScreen } from '../../SharedComponents/LoadingScreen';
import ErrorPopup from '../../SharedComponents/ErrorPopup';
import BadRequestPopup from '../../SharedComponents/BadRequestPopup';
import { formatYearby } from '../../../utils/ReturnCheckUtils';

export function Footer() {
  const history = useHistory();
  const routePath: any = useParams();
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const { classes, paymentRequest, saveCheck, setCancelReason } =
    useContext(ReturnCheckContext);
  const [checkAdded, setCheckAdded] = useState<boolean>();
  const [checkCancelled, setCheckCancelled] = useState<boolean>(false);
  const [screenLoader, setScreenLoader] = useState<boolean>(false);
  const [badRequest, setBadRequest] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const fieldsValidator =
    saveCheck &&
    paymentRequest.returnedDate &&
    paymentRequest.returnedDate !== '' &&
    paymentRequest.returnedAmount &&
    paymentRequest.returnedAmount !== '' &&
    paymentRequest.returnedReason &&
    paymentRequest.returnedReason !== '';
  async function saveCheckInfo() {
    try {
      if (fieldsValidator) {
        setScreenLoader(true);

        const savedResponse = await manageCheckPayment({
          transactionId: routePath?.transactionId,
          returnedDate: formatYearby(paymentRequest?.returnedDate),
          returnedAmount: paymentRequest?.returnedAmount,
          returnedReason: paymentRequest?.returnedReason,
        });

        setScreenLoader(false);

        if (
          savedResponse &&
          savedResponse?.status == 200 &&
          savedResponse?.data?.transactionId
        ) {
          setCheckAdded(true);
        } else if (
          savedResponse &&
          ((savedResponse?.status == 200 &&
            savedResponse?.data?.value?.code == 400) ||
            savedResponse?.status == 400)
        ) {
          setBadRequest(true);
        } else {
          setShowError(true);
        }
      }
    } catch (e: any) {
      setShowError(true);
    }
  }
  return (
    <>
      <Grid className={`${classes.fixedFooter}`}>
        <Grid className={classes.floatLeft}>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </RACButton>
        </Grid>

        <Grid className={`${classes.floatRight}`}>
          {routePath?.category == 'paidonreturncheck' ||
          routePath?.category == 'paidinreturncheck' ? (
            <></>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              disabled={fieldsValidator ? false : true}
              onClick={() => {
                saveCheckInfo();
              }}
            >
              Save
            </RACButton>
          )}
        </Grid>
        {routePath.category == 'edit' ? (
          <Grid className={`${classes.floatRight} ${classes.mr3}`}>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.errorbutton}`}
              onClick={() => {
                setCancelReason('');
                setCancelPopup(true);
              }}
            >
              Cancel Check
            </RACButton>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {screenLoader ? <LoadingScreen /> : <></>}
      {showError ? <ErrorPopup setFunction={setShowError} /> : <></>}
      {badRequest ? <BadRequestPopup /> : <></>}
      {checkCancelled ? (
        <VerifiedPopup
          message={'Returned Check Cancelled Successfully'}
          isCancel={true}
        />
      ) : (
        <></>
      )}
      {checkAdded ? (
        <VerifiedPopup
          message={
            routePath?.category == 'add'
              ? 'Returned Check Added Successfully'
              : 'Returned Check Updated Successfully'
          }
        />
      ) : (
        <></>
      )}
      {cancelPopup ? (
        <CancellationPopup
          setFunction={setCancelPopup}
          badRequest={setBadRequest}
          error={setShowError}
          verified={setCheckCancelled}
        />
      ) : (
        <></>
      )}
    </>
  );
}
