import { Grid, Typography, RACButton } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { ReactComponent as Filter } from '../../../Assets/Icons/Filter.svg';
import { ReactComponent as Expand } from '../../../Assets/Icons/down-arrow-filter.svg';
import { ReactComponent as Collapse } from '../../../Assets/Icons/up-arrow-filter.svg';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';

export function SearchHeader() {
  const { classes, showFilter, setShowFilter, searchStatus } =
    useContext(ReturnCheckContext);

  return (
    <>
      <Grid container md={12}>
        <Grid item md={11} sm={10} xs={9} lg={11}>
          <Typography
            variant="h5"
            className={`${classes.title} ${classes.newCard}`}
          >
            Returned Check
          </Typography>
        </Grid>
        <Grid item md={1} sm={2} xs={3} lg={1} className={classes.headerStyle}>
          <RACButton
            variant="contained"
            color="primary"
            startIcon={<Filter />}
            disabled={searchStatus == 0 ? true : false}
            endIcon={showFilter ? <Collapse /> : <Expand />}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            Filter
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
}
