import React from 'react';
import { Route } from 'react-router-dom';
import { AppRoute } from '../../config/route-config';
import CreditCardProvider from './CreditcardContext';
import CCDetails from './CreditcardDetails/CCDetails';
import Chargeback from './CreditcardSearch/CreditcardSearch';
export const FinanceRoute = () => {
  return (
    <>
      <CreditCardProvider>
        <Route exact path={AppRoute.Chargeback}>
          <Chargeback />
        </Route>
        <Route exact path={AppRoute.Details}>
          <CCDetails />
        </Route>
      </CreditCardProvider>
    </>
  );
};
