import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { ProductPermissions } from '../../constants/constants';

import { useUserStateContext } from '../user/user-contexts';

export interface PermissionsState {
  canPlaceholder: boolean;
}

export const defaultState = {
  canPlaceholder: false,
};

enum ActionType {
  MAP_PERMISSIONS = 'MAP_PERMISSIONS',
}

interface PermissionsAction {
  type: ActionType.MAP_PERMISSIONS;
  payload: ProductPermissions[];
}

export function permissionsReducer(
  state: PermissionsState,
  { type, payload }: PermissionsAction
): PermissionsState {
  if (type === ActionType.MAP_PERMISSIONS) {
    return {
      ...state,
      canPlaceholder: payload.includes(ProductPermissions.PLACEHOLDER),
    };
  } else {
    return state;
  }
}

export const PermissionsContext = createContext<PermissionsState>(defaultState);

export const useUserPermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(permissionsReducer, defaultState);

  const { user } = useUserStateContext();

  useEffect(() => {
    if (!user) return;

    dispatch({
      type: ActionType.MAP_PERMISSIONS,
      payload: user.permissions,
    });
  }, [user]);

  return (
    <PermissionsContext.Provider value={state}>
      {props.children}
    </PermissionsContext.Provider>
  );
};
