import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import { ReturnCheckContextProvider } from '../context/ReturnCheck/ReturnCheckContext';
import { CheckDetails } from '../components/ReturnCheck/CheckDetails';
import { FinanceRoute } from '../components/CreditcardChargeback/FinanceRoutes';
import { CheckSearch } from '../components/ReturnCheck/CheckSearch';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    <PermissionsProvider>
      <UserFetcher />
      <div data-testid={routesTestId}>
        <Switch>
          <Route path={AppRoute.CCB}>
            <FinanceRoute />
          </Route>
          <ReturnCheckContextProvider>
            <Route exact path={AppRoute.checkSearch}>
              <CheckSearch />
            </Route>
            <Route exact path={AppRoute.checkDetails}>
              <CheckDetails />
            </Route>
          </ReturnCheckContextProvider>
        </Switch>
      </div>
    </PermissionsProvider>
  </UserProvider>
);
