import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  RACButton,
  RACTable,
  RACTableCell,
  RACTableRow,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as SortDesc } from '../../../Assets/Icons/SortDown.svg';
import { ReactComponent as SortAsc } from '../../../Assets/Icons/SortUp.svg';
import { ReactComponent as AddCheck } from '../../../Assets/Icons/ItemAddition.svg';
import React, { useContext, useState } from 'react';
import {
  sortPaymentDate,
  formatCurrency,
  formatDate,
  searchChecks,
  categoryType,
} from '../../../utils/ReturnCheckUtils';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { DEFAULT_CHECK_LIMIT } from '../../../constants/constants';
import { CancellationPopup } from './CancellationPopup';
import VerifiedPopup from '../../SharedComponents/VerifiedPopup';

export function ResultWidget() {
  const {
    classes,
    filteredChecks,
    setFilteredChecks,
    searchRequest,
    setSearchRequest,
    showFilter,
  } = useContext(ReturnCheckContext);
  const history = useHistory();

  const resultHeadings = [
    'Store',
    'Check #',
    'Check Written By',
    'Payment Date ',
    'Category',
    'Amount',
    'Action',
  ];
  const [sort, setSort] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [successPopup, setSuccessPopup] = useState<boolean>(false);

  async function fetchNextData() {
    try {
      if (filteredChecks.length > 9) {
        setLoader(true);
        const searchResponse: any = await searchChecks(searchRequest);
        setLoader(false);
        if (searchResponse && searchResponse?.status == 200) {
          if (searchResponse?.response?.length < DEFAULT_CHECK_LIMIT) {
            setHasMore(false);
          }
          setSearchRequest({
            ...searchRequest,
            offset: searchRequest?.offset + DEFAULT_CHECK_LIMIT,
          });
          setFilteredChecks([...filteredChecks, ...searchResponse?.response]);
        } else {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (err: any) {
      setLoader(false);
      setHasMore(false);
    }
  }

  const renderResultHeader = () => (
    <>
      {resultHeadings?.map((obj: any, index: any) => {
        if (obj == 'Payment Date ') {
          return (
            <RACTableCell key={index}>
              {obj}
              {filteredChecks?.length <= 1 ? (
                <></>
              ) : sort ? (
                <SortDesc
                  className={classes.cursor}
                  onClick={() => {
                    sortPaymentDate(filteredChecks, 'asc');
                    setSort(!sort);
                  }}
                />
              ) : (
                <SortAsc
                  className={classes.cursor}
                  onClick={() => {
                    sortPaymentDate(filteredChecks, 'desc');
                    setSort(!sort);
                  }}
                />
              )}
            </RACTableCell>
          );
        }
        if (obj == 'Action') {
          return (
            <RACTableCell key={index} className={classes.centerAlign}>
              {obj}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj}</RACTableCell>;
      })}
    </>
  );

  const resultFirstHalf = (obj: any, index: number) => (
    <>
      <RACTableCell
        key={index}
        onClick={() => {
          obj?.storeNumber
            ? history.push({
                pathname: `/operations/store/${obj?.storeNumber}`,
              })
            : null;
        }}
      >
        {obj?.storeNumber ? (
          <Typography className={`${classes.racpadLink}`}>
            {obj?.storeNumber}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj?.checkNumber ? (
          <Typography className={`${classes.formValue}`}>
            {obj?.checkNumber}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj?.checkWrittenBy ? (
          <Typography className={`${classes.formValue}`}>
            {obj?.checkWrittenBy}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
    </>
  );

  const resultSecondHalf = (obj: any, index: number) => (
    <>
      <RACTableCell key={index}>
        {obj?.paymentDate ? (
          <Typography className={`${classes.formValue}`}>
            {formatDate(obj?.paymentDate)}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj?.category ? (
          <Typography className={`${classes.formValue}`}>
            {obj?.category ? categoryType(obj?.category) : '-'}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj?.amount ? (
          <Typography className={`${classes.formValue}`}>
            {formatCurrency(obj?.amount)}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
    </>
  );

  const renderResultContent = () => (
    <>
      {filteredChecks?.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index} className={classes.whiteBgColor}>
            {resultFirstHalf(obj, index)}
            {resultSecondHalf(obj, index)}

            {actionButtons(obj)}
          </RACTableRow>
        );
      })}
    </>
  );

  function actionButtons(obj: any) {
    return (
      <>
        {obj?.category == 'PAIDONRETURNEDCHECK' || obj?.isPaidIn ? (
          <RACTableCell className={classes.centerAlign}>
            <RACButton
              variant="outlined"
              size="small"
              color="primary"
              className={classes.actionButtons}
              onClick={() => {
                history.push({
                  pathname: obj?.isPaidIn
                    ? `returncheck/paidinreturncheck/${obj?.transactionId}`
                    : `returncheck/paidonreturncheck/${obj?.transactionId}`,
                });
              }}
            >
              View
            </RACButton>
          </RACTableCell>
        ) : obj?.category == 'CHECKPAYMENT' ? (
          <RACTableCell className={classes.centerAlign}>
            <AddCheck
              className={classes.actionSize}
              onClick={() => {
                history.push({
                  pathname: `returncheck/add/${obj?.transactionId}`,
                });
              }}
            />
          </RACTableCell>
        ) : obj?.category == 'RETURNEDCHECK' ? (
          <RACTableCell className={classes.centerAlign}>
            <RACButton
              variant="outlined"
              size="small"
              color="primary"
              className={classes.actionButtons}
              onClick={() => {
                history.push({
                  pathname: `returncheck/edit/${obj?.transactionId}`,
                });
              }}
            >
              Edit
            </RACButton>

            <RACButton
              variant="outlined"
              size="small"
              className={classes.cancelButtonColor}
              onClick={() => {
                setCancelPopup(true);
                setTransactionId(obj?.transactionId?.toString());
              }}
            >
              Cancel
            </RACButton>
          </RACTableCell>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      {cancelPopup ? (
        <CancellationPopup
          transactionId={transactionId}
          forSearch={true}
          setFunction={setCancelPopup}
          verified={setSuccessPopup}
        />
      ) : (
        <></>
      )}
      {successPopup ? (
        <VerifiedPopup
          message={'Returned Check Cancelled Successfully'}
          setFunction={setSuccessPopup}
          isCancel={true}
        />
      ) : (
        <></>
      )}

      <Card className={`${classes.cardBorder} ${classes.heightAuto}`}>
        <CardContent>
          <InfiniteScroll
            next={fetchNextData}
            dataLength={filteredChecks?.length}
            hasMore={hasMore}
            height={
              showFilter
                ? '65vh'
                : filteredChecks?.length >= 25
                ? '85vh'
                : 'auto'
            }
            loader={
              loader ? (
                <Grid className={`${classes.infiniteLoader}`}>
                  <CircularProgress />
                </Grid>
              ) : null
            }
          >
            <RACTable
              className={`${classes.textFixHead}`}
              renderTableHead={renderResultHeader}
              renderTableContent={renderResultContent}
              stickyHeader
            />
          </InfiniteScroll>
        </CardContent>
      </Card>
    </>
  );
}
