import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../Assets/Icons/Error.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReturnCheckStyles } from '../../JsStyles/ReturnCheckStyles';

export default function BadRequestPopup(props?: any) {
  const classes = ReturnCheckStyles();
  const history = useHistory();
  const hasFunction = props && props?.setFunction ? true : false;
  return (
    <RACModalCard
      isOpen={true}
      closeIcon={false}
      maxWidth="xs"
      borderRadius="25px !important"
    >
      <div
        id="errorpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.centerAlign}>
          <AlertIcon></AlertIcon>
          <Typography variant="h6" className={`  ${classes.mt2}`}>
            Bad Request
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.centerAlign}`}
        >
          <RACButton
            className={classes.mlr1}
            variant="contained"
            color="primary"
            onClick={() => {
              hasFunction ? props.setFunction(false) : history.goBack();
            }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    </RACModalCard>
  );
}
