import {
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
  Card,
  RACDateRangePicker,
  CardContent,
} from '@rentacenter/racstrap';
import { CreditCardContext } from '../CreditcardContext';
import React, { useContext } from 'react';
export default function CCsearchfilter(props: any) {
  const {
    classes,
    handleCharge,
    sethandleCharge,
    status,
    ONLY_NUMBERS,
    setDisplaySearch,
    dropDownloading,
    validationData,
    clearButtonDisable,
  } = useContext(CreditCardContext);
  // const nameRegexFn = (e: any) =>
  //   props?.handleInputChange(e, /^[0-9]+$/, /[^0-9]/g);

  return (
    <Grid container md={12}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container md={12} spacing={2}>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="store"
                id="store"
                inputlabel="Store #"
                maxlength={5}
                value={handleCharge.storeNumber}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  if (!ONLY_NUMBERS.test(e.target.value)) {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }
                  sethandleCharge({
                    ...handleCharge,
                    storeNumber: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="creditcard"
                id="creditcard"
                maxlength={4}
                inputlabel="CC Last 4 Digit"
                value={handleCharge.lastFourDigit}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  if (!ONLY_NUMBERS.test(e.target.value)) {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }
                  sethandleCharge({
                    ...handleCharge,
                    lastFourDigit: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="Authorization"
                id="Authorization"
                inputlabel="Authorization #"
                maxlength={30}
                value={handleCharge?.authorizationNum}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  sethandleCharge({
                    ...handleCharge,
                    authorizationNum: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={4} lg={4} sm={8} xs={12}>
              <RACDateRangePicker
                fromLabel={'From Date'}
                toLabel={'To Date'}
                fromValue={
                  handleCharge.fromDate == ''
                    ? handleCharge.toDate
                    : handleCharge.fromDate
                }
                toValue={
                  handleCharge.toDate == ''
                    ? handleCharge.fromDate
                    : handleCharge.toDate
                }
                onFromChanged={(e: any) => {
                  sethandleCharge({ ...handleCharge, fromDate: e });
                }}
                onToChanged={(e: any) => {
                  sethandleCharge({ ...handleCharge, toDate: e });
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="number"
                name="amount"
                inputlabel="Amount"
                placeHolder={'0.00'}
                //inputTextBoxClassname={classes.currencyField}
                dollarTextClassName={`${classes.dollar}`}
                className={classes.currencyField}
                value={handleCharge.amount}
                //dollarTextClassName={classes.dollarSymbol}
                isCurrency={true}
                OnChange={(e: any) => {
                  sethandleCharge({ ...handleCharge, amount: e.target.value });
                }}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid container md={12} spacing={2}>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACSelect
                inputLabel="Status"
                options={status}
                loading={dropDownloading}
                name="status"
                onChange={(e) => {
                  sethandleCharge({ ...handleCharge, status: e.target.value });
                }}
                defaultValue={handleCharge.status}
              />
            </Grid>
            <Grid
              item
              md={10}
              lg={10}
              sm={12}
              xs={12}
              className={`${classes.mt2}`}
            >
              <RACButton
                color="primary"
                variant="contained"
                size="small"
                disabled={validationData ? false : true}
                className={`${classes.floatRight}`}
                onClick={() => {
                  props?.fetchCreditcardSearchData();
                  setDisplaySearch(false);
                }}
              >
                Apply
              </RACButton>
              <RACButton
                color="primary"
                variant="outlined"
                size="large"
                style={clearButtonDisable ? {} : { color: '#7FA6FF' }}
                disabled={clearButtonDisable ? false : true}
                className={`${classes.floatRight} ${classes.mR1} ${classes.padding6}`}
                onClick={() => {
                  props?.clearSearch();
                }}
              >
                Clear
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
