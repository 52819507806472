import { client, clientCall } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
import { ProductPermissions } from '../constants/constants';
import { User } from '../types/types';

export const getCurrentUser = (): Promise<User> =>
  client('users/current', { method: 'GET' }, appConfig.apiUrls.container);

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container
  );
export const getCCPaymentSummary = async (Payload: any) => {
  return await clientCall(
    `cc/summary`,
    { method: 'POST', payload: Payload },
    appConfig.apiUrls.micro,
    true
  );
};
export const getCancelDropDown = async () => {
  try {
    return await clientCall(
      `customer/customerinfo-options/0`,
      { method: 'GET' },
      appConfig.apiUrls.customer
    );
  } catch (e: any) {
    return null;
  }
};
export const getCCPaymentDetails = async (transId: string) => {
  return await clientCall(
    `cc/details/${transId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    true
  );
};
export const manageCCDetails = async (Payload: any) => {
  return await clientCall(
    `manage`,
    { method: 'POST', payload: Payload },
    appConfig.apiUrls.micro,
    true
  );
};
export const getCheckPaymentDetails = async (transactionId: string) => {
  return await clientCall(
    `check/details/${transactionId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro
  );
};

export const manageCheckPayment = async (body: any) => {
  return await clientCall(
    `check/manage`,
    {
      method: 'POST',
      payload: body,
    },
    appConfig.apiUrls.micro
  );
};

export const getDropDownData = async () => {
  return await clientCall(
    `customer/customerinfo-options/0`,
    {
      method: 'GET',
    },
    appConfig.apiUrls.customer
  );
};

export const getCheckPaymentSummary = async (body: any) => {
  return await clientCall(
    `check/summary`,
    {
      method: 'POST',
      payload: body,
    },
    appConfig.apiUrls.micro
  );
};
