/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACDateRangePicker,
  RACSelect,
  RACTextbox,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect } from 'react';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import {
  CHECK_STATUSES,
  DEFAULT_CHECK_LIMIT,
  ONLY_LETTERS,
  ONLY_NUMBERS,
} from '../../../constants/constants';
import {
  getCheckDropdowns,
  searchChecks,
  validateFields,
} from '../../../utils/ReturnCheckUtils';

export function FilterWidget() {
  const {
    classes,
    setShowFilter,
    searchParams,
    setSearchParams,
    setSearchRequest,
    addReasons,
    setAddReasons,
    dropReasons,
    setDropReasons,
    setDropdownLoader,
    setFilteredChecks,
    setSearchStatus,
    reloadData,
    setReloadData,
  } = useContext(ReturnCheckContext);
  const emptyFields =
    searchParams?.storeNumber != '' ||
    searchParams?.amount !== '' ||
    searchParams?.checkNumber !== '' ||
    searchParams?.fromDate !== '' ||
    searchParams?.toDate !== '' ||
    searchParams?.checkWrittenBy !== '';

  useEffect(() => {
    if (reloadData) {
      setReloadData(false);
      getChecks();
    }
  }, []);

  function handleOnChange(key: string, value: string, fieldType: string) {
    if (fieldType == 'number' && !ONLY_NUMBERS.test(value)) {
      value = value.replace(/[^0-9]/g, '');
    }

    if (fieldType == 'text' && !ONLY_LETTERS.test(value)) {
      value = value.replace(/[^A-Z a-z]/gi, '');
    }

    setSearchParams({ ...searchParams, [key]: value });
  }

  function handleKeyDown(key: string) {
    if (key == 'Enter') {
      setShowFilter(false);
      getChecks();
    }
  }

  function formPayload() {
    try {
      return {
        storeNumber:
          searchParams?.storeNumber.trim() !== ''
            ? searchParams?.storeNumber.trim()
            : null,
        amount:
          searchParams?.amount !== ''
            ? searchParams?.amount.replace(/,/g, '', 10)
            : null,
        checkNumber:
          searchParams?.checkNumber !== ''
            ? searchParams.checkNumber.trim()
            : null,
        fromDate:
          searchParams?.fromDate !== ''
            ? searchParams?.fromDate
            : searchParams?.toDate !== ''
            ? searchParams?.toDate
            : null,
        toDate:
          searchParams?.toDate !== ''
            ? searchParams?.toDate
            : searchParams?.fromDate !== ''
            ? searchParams?.fromDate
            : null,
        checkWrittenBy:
          searchParams?.checkWrittenBy.trim() !== ''
            ? searchParams?.checkWrittenBy.trim()
            : null,
        status: searchParams?.status !== '' ? searchParams?.status : null,
        offset: 0,
        limit: DEFAULT_CHECK_LIMIT,
      };
    } catch (err: any) {
      return {};
    }
  }

  async function getChecks() {
    try {
      const isValidSearch = validateFields(searchParams);
      if (isValidSearch) {
        setSearchStatus(50);

        const payload = formPayload();

        const formattedResponse = await searchChecks(payload);
        // eslint-disable-next-line no-console
        console.log(formattedResponse, 'Formated response');
        setSearchStatus(formattedResponse?.status);

        if (formattedResponse?.status == 200) {
          setSearchRequest({
            storeNumber: payload?.storeNumber,
            amount: payload?.amount,
            checkNumber: payload?.checkNumber,
            fromDate: payload?.fromDate,
            toDate: payload?.toDate,
            checkWrittenBy: payload?.checkWrittenBy,
            status: payload?.status,
            offset: DEFAULT_CHECK_LIMIT,
            limit: DEFAULT_CHECK_LIMIT,
          });
          setFilteredChecks(formattedResponse.response);
          if (
            searchParams?.status == 'ALL' ||
            (searchParams?.status == 'RCHK' &&
              (addReasons.length == 1 || dropReasons.length == 1))
          ) {
            const reasons = await getCheckDropdowns();
            setDropReasons(reasons.cancelReasons);
            setAddReasons(reasons.returnReasons);
          }
        }
        setDropdownLoader(false);
      }
    } catch (err: any) {
      setSearchStatus(500);
    }
  }
  return (
    <>
      <Grid>
        <Card className={`${classes.cardBorder}`}>
          <CardContent className={`${classes.p5} ${classes.p17}`}>
            <Grid container md={12} spacing={2} className={`${classes.mtb1}`}>
              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  type="text"
                  name="storeNumber"
                  id="storeNumber"
                  inputlabel="Store #"
                  value={searchParams?.storeNumber}
                  maxlength={5}
                  OnChange={(e: any) => {
                    handleOnChange(e?.target?.name, e?.target?.value, 'number');
                  }}
                  OnKeydown={(e: any) => {
                    handleKeyDown(e?.key);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  type="number"
                  name="amount"
                  id="amount"
                  inputlabel="Amount"
                  className={classes.currencyField}
                  dollarTextClassName={classes.dollarCss}
                  value={searchParams?.amount}
                  isCurrency={true}
                  maxlength={22}
                  OnChange={(e: any) => {
                    handleOnChange(e?.target?.name, e?.target?.value, '');
                  }}
                  OnKeydown={(e: any) => {
                    handleKeyDown(e?.key);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  type="text"
                  name="checkNumber"
                  id="checkNumber"
                  inputlabel="Check #"
                  value={searchParams?.checkNumber}
                  OnChange={(e: any) => {
                    handleOnChange(e?.target?.name, e?.target?.value, '');
                  }}
                  OnKeydown={(e: any) => {
                    handleKeyDown(e?.key);
                  }}
                />
              </Grid>

              <Grid item md={4} lg={4} sm={4} xs={12}>
                <RACDateRangePicker
                  id="dateRange"
                  fromLabel={'From Date'}
                  toLabel={'To Date'}
                  fromValue={
                    searchParams?.fromDate == ''
                      ? searchParams?.toDate
                      : searchParams?.fromDate
                  }
                  toValue={
                    searchParams?.toDate == ''
                      ? searchParams?.fromDate
                      : searchParams?.toDate
                  }
                  onFromChanged={(e: any) => {
                    handleOnChange('fromDate', e, '');
                  }}
                  onToChanged={(e: any) => {
                    handleOnChange('toDate', e, '');
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  type="text"
                  name="checkWrittenBy"
                  id="checkWrittenBy"
                  inputlabel="Check Written By"
                  value={searchParams?.checkWrittenBy}
                  maxlength={35}
                  OnChange={(e: any) => {
                    handleOnChange('checkWrittenBy', e?.target?.value, 'text');
                  }}
                  OnKeydown={(e: any) => {
                    handleKeyDown(e?.key);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container md={12} spacing={2}>
              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACSelect
                  inputLabel={'Status'}
                  options={CHECK_STATUSES}
                  name="checkStatus"
                  defaultValue={searchParams?.status}
                  onChange={(e: any) => {
                    handleOnChange('status', e?.target?.value, '');
                  }}
                />
              </Grid>

              <Grid
                item
                md={10}
                lg={10}
                sm={10}
                xs={12}
                className={`${classes.mt2}  ${classes.textRight}`}
              >
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={`${classes.mr3} ${classes.buttonPadding} `}
                  style={emptyFields ? {} : { color: '#7FA6FF' }}
                  disabled={!emptyFields}
                  onClick={() => {
                    setSearchParams({
                      storeNumber: '',
                      amount: '',
                      checkNumber: '',
                      fromDate: '',
                      toDate: '',
                      checkWrittenBy: '',
                      status: 'ALL',
                      offset: 0,
                      limit: DEFAULT_CHECK_LIMIT,
                    });
                  }}
                >
                  Clear
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={validateFields(searchParams) ? false : true}
                  onClick={() => {
                    setShowFilter(false);
                    getChecks();
                  }}
                >
                  Apply
                </RACButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
