import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Sortup } from '../../../Assets/sort-up-icon.svg';
import { ReactComponent as Sortdown } from '../../../Assets/Sort.svg';
import { ReactComponent as Filterdown } from '../../../Assets/down-arrow-filter.svg';
import { ReactComponent as Filterup } from '../../../Assets/up-arrow-filter.svg';
import { ReactComponent as Filter } from '../../../Assets/Filter.svg';
import { ReactComponent as Addicon } from '../../../Assets/ItemAddition.svg';
import BadRequestPopup from '../../CreditcardChargeback/BadRequest';
import ErrorPopup from '../../SharedComponents/ErrorPopup';
import CCsearchfilter from './CreditcardSearchFilter';
import VerifiedPopup from '../VerifiedPopup';
import { Cancelpopup } from '../Cancelpopup';
import { getCCDropdowns } from './Commonfunctions';
import { getCCPaymentSummary } from '../../../api/user';
import {
  formatDate,
  formatePayment,
  ChargebackHeadings,
  CCcategoryType,
} from './Commonfunctions';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import CCsearchgrid from './CreditcardSearchgrid';
import { CreditCardContext } from '../CreditcardContext';
import {
  Grid,
  Typography,
  RACButton,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { handleCharge } from '../../../interface';
export default function Chargeback() {
  const history = useHistory();
  const classes = EmilyGlobalStyles();
  const {
    handleCharge,
    sethandleCharge,
    displaySearch,
    setDisplaySearch,
    displaySearchResults,
    setDisplaySearchResults,
    isSort,
    setisSort,
    setPageDisplay,
    viewableRecords,
    setViewableRecords,
    validationData,
    setLoader,
    filterDisable,
    setFilterDisable,
    addReasons,
    dropReasons,
    setDropdownLoader,
    setAddReasons,
    setDropReasons,
    setCancelReason,
    footer,
    setFooter,
    setHasMore,
  } = useContext(CreditCardContext);

  useEffect(() => {
    footer ? fetchCreditcardSearchData() : setFooter(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [transactionId, setTransactionId] = useState('');
  const [badRequest, setBadRequest] = useState<boolean>(false);
  const [checkCancelled, setCheckCancelled] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  function handleOnChange(e: any) {
    sethandleCharge({
      ...handleCharge,
      [e?.target?.name]: e?.target?.value,
    });
  }

  function onKeyPress(e: any) {
    validationData && e.key == 'Enter' ? fetchCreditcardSearchData() : null;
    validationData && e.key == 'Enter' ? setDisplaySearch(false) : null;
  }

  const handleInputChange = (e: any, regex: any, replace: any) => {
    !regex.test(e.target.value)
      ? (e.target.value = e.target.value.replace(replace, ''))
      : null;
    handleOnChange(e);
  };
  const renderCCPaymentTableHeadFn = () => (
    <>
      {ChargebackHeadings.map((obj: any) => {
        return obj.heading == 'Payment Date' ? (
          <RACTableCell>
            {obj.heading}
            {viewableRecords?.length <= 1 ? (
              <></>
            ) : isSort ? (
              <Sortdown
                className={` ${classes.sortimg} ${classes.currencyAlign} ${classes.cursorPointer}`}
                onClick={() => {
                  sortGrid('asc', viewableRecords), setisSort(!isSort);
                }}
              />
            ) : (
              <Sortup
                onClick={() => {
                  sortGrid('desc', viewableRecords), setisSort(!isSort);
                }}
              />
            )}
          </RACTableCell>
        ) : obj.heading == 'Action' ? (
          <RACTableCell style={{ textAlign: 'center' }}>
            {obj.heading}
          </RACTableCell>
        ) : (
          <RACTableCell>{obj.heading}</RACTableCell>
        );
      })}
    </>
  );

  const sortGrid = (order: string, Array: any[]) => {
    const dateSort = (a: any, b: any) => {
      const dateA: any = new Date(a.paymentDate);
      const dateB: any = new Date(b.paymentDate);
      if (order == 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    };
    Array.sort(dateSort);
  };
  const renderContentPart = (obj: any, index: any) => (
    <>
      <RACTableCell
        key={index}
        onClick={() => {
          obj.customerName
            ? history.push({
                pathname: `/operations/customer/details/${obj?.customerId}`,
              })
            : null;
        }}
      >
        {obj.customerName ? (
          <Typography className={`${classes.racpadLink}`}>
            {obj.customerName}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj.lastFourDigit ? (
          <Typography className={`${classes.formValue}`}>
            {obj.lastFourDigit}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj.authorizationNum ? (
          <Typography className={`${classes.formValue}`}>
            {obj.authorizationNum}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
      <RACTableCell key={index}>
        {obj.amount ? (
          <Typography className={`${classes.formValue}`}>
            {formatePayment(obj.amount)}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
    </>
  );
  const renderContentHelper = (obj: any) => (
    <>
      {obj.category == 'Creditcard Payment' ? (
        <RACTableCell style={{ textAlign: 'center' }}>
          <Addicon
            style={{ cursor: 'pointer', height: '22px' }}
            onClick={() => {
              history.push({
                pathname: `creditcardcc/addchargeback/${obj.transactionId}`,
              });
            }}
          />
        </RACTableCell>
      ) : (
        <></>
      )}
      {obj.category == 'Chargeback' ? (
        <RACTableCell style={{ textAlign: 'center' }}>
          <RACButton
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `creditcardcc/editchargeback/${obj.transactionId}`,
              });
            }}
            style={{ padding: '2px 7px', marginRight: '8px' }}
          >
            Edit
          </RACButton>
          <RACButton
            variant="outlined"
            size="small"
            style={{
              padding: '3px 8px',
              color: '#ff3333',
              border: '0.5px solid #FFD9D9',
              backgroundColor: '#FFFAFA',
            }}
            onClick={() => {
              setCancelReason('');
              setCancelPopup(true);
              setTransactionId(obj?.transactionId?.toString());
            }}
          >
            Cancel
          </RACButton>
        </RACTableCell>
      ) : (
        <></>
      )}
      {obj.category == 'Paid Chargeback' ? (
        <RACTableCell style={{ textAlign: 'center' }}>
          <RACButton
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `creditcardcc/viewchargeback/${obj.transactionId}`,
              });
            }}
            style={{ padding: '2px 7px', marginRight: '8px' }}
          >
            View
          </RACButton>
        </RACTableCell>
      ) : (
        <></>
      )}
    </>
  );

  const renderCCPaymentTableContentFn = () => (
    <>
      {viewableRecords?.map((obj: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <RACTableRow style={{ backgroundColor: 'white' }}>
              <RACTableCell
                key={index}
                onClick={() => {
                  obj.storeNumber
                    ? history.push({
                        pathname: `/operations/store/${obj?.storeNumber}`,
                      })
                    : null;
                }}
              >
                {obj.storeNumber ? (
                  <Typography className={`${classes.racpadLink}`}>
                    {obj.storeNumber}
                  </Typography>
                ) : (
                  '-'
                )}
              </RACTableCell>
              <RACTableCell key={index}>
                {obj.paymentDate ? (
                  <Typography className={`${classes.formValue}`}>
                    {formatDate(obj.paymentDate)}
                  </Typography>
                ) : (
                  '-'
                )}
              </RACTableCell>
              <RACTableCell key={index}>
                {obj.category ? (
                  <Typography className={`${classes.formValue}`}>
                    {CCcategoryType(obj.category)}
                  </Typography>
                ) : (
                  '-'
                )}
              </RACTableCell>
              {renderContentPart(obj, index)}
              {renderContentHelper(obj)}
            </RACTableRow>
          </React.Fragment>
        );
      })}
    </>
  );
  async function fetchCreditcardSearchData() {
    try {
      setLoader(true);
      setHasMore(true);
      sethandleCharge({ ...handleCharge, offset: 20 });
      const payload: handleCharge = {
        storeNumber:
          handleCharge.storeNumber !== '' ? handleCharge.storeNumber : null,
        amount:
          handleCharge.amount !== ''
            ? handleCharge.amount.replace(/,/g, '', 10)
            : null,
        lastFourDigit:
          handleCharge.lastFourDigit !== '' ? handleCharge.lastFourDigit : null,
        fromDate:
          handleCharge.fromDate !== ''
            ? handleCharge.fromDate
            : handleCharge.toDate !== ''
            ? handleCharge.toDate
            : null,
        toDate:
          handleCharge.toDate !== ''
            ? handleCharge.toDate
            : handleCharge.fromDate !== ''
            ? handleCharge.fromDate
            : null,
        authorizationNum:
          handleCharge.authorizationNum !== ''
            ? handleCharge.authorizationNum
            : null,
        status: handleCharge.status !== '' ? handleCharge.status : null,
        offset: 0,
        limit: handleCharge.limit,
      };
      setDisplaySearchResults(true);
      setFilterDisable(false);
      const fetchedData: any = await getCCPaymentSummary(payload);
      fetchedData?.status === 200
        ? handleSuccessPageDisplay(fetchedData)
        : handleFailurePageDisplay(fetchedData);
    } catch (error) {
      handleFetchError();
    }
  }
  async function handleSuccessPageDisplay(fetchedData: any) {
    setPageDisplay(
      fetchedData?.data?.length === 0 ||
        fetchedData?.data?.response?.length === 0
        ? 204
        : 200
    );
    setLoader(false);
    if (fetchedData?.data) {
      fetchedData?.data?.response
        ? setViewableRecords([])
        : setViewableRecords(fetchedData?.data);
    }
    if (
      handleCharge?.status == 'ALL' ||
      (handleCharge?.status == 'CHB' &&
        (addReasons?.length == 1 || dropReasons?.length == 1))
    ) {
      const dropdownvalues = await getCCDropdowns();
      setDropdownLoader(false);
      setAddReasons(dropdownvalues?.returnReasons);
      setDropReasons(dropdownvalues?.cancelReasons);
    }
    //  fetchedData.data?.length > 19 ? sethandleCharge({...handleCharge,offset:handleCharge.offset+20}) : sethandleCharge({...handleCharge,offset:handleCharge.offset});
  }

  function handleFailurePageDisplay(fetchedData: any) {
    setLoader(false);
    setPageDisplay(fetchedData.status === 400 ? 400 : 500);
  }

  function handleFetchError() {
    setLoader(false);
    setPageDisplay(500);
  }

  function clearSearch() {
    sethandleCharge({
      ...handleCharge,
      storeNumber: '',
      lastFourDigit: '',
      authorizationNum: '',
      fromDate: '',
      toDate: '',
      amount: '',
      status: 'ALL',
    });
  }
  return (
    <Grid container md={12} className={`${classes.pagePadding1}`}>
      <Grid container className={`${classes.mb3}`}>
        <Grid item md={6}>
          <Typography
            className={`${classes.floatLeft} ${classes.mt9} ${classes.title1} ${classes.mL1}`}
          >
            Credit Card Chargeback
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={6} xs={6}>
          <RACButton
            color="primary"
            variant="contained"
            size="large"
            className={`${classes.mt9} ${classes.mR1} ${classes.floatRight} ${classes.padding6}`}
            disabled={filterDisable}
            onClick={() => {
              displaySearchResults
                ? setDisplaySearch(!displaySearch)
                : setDisplaySearch(displaySearch);
            }}
          >
            <Filter className={`${classes.mR1}`} />
            Filter
            {displaySearch ? (
              <Filterup className={`${classes.mL1}`} />
            ) : (
              <Filterdown className={`${classes.mL1}`} />
            )}
          </RACButton>
        </Grid>
      </Grid>

      {displaySearch ? (
        <CCsearchfilter
          onKeyPress={onKeyPress}
          handleInputChange={handleInputChange}
          handleOnChange={handleOnChange}
          fetchCreditcardSearchData={fetchCreditcardSearchData}
          clearSearch={clearSearch}
        />
      ) : (
        <></>
      )}

      {displaySearchResults ? (
        <CCsearchgrid
          renderCCPaymentTableHeadFn={renderCCPaymentTableHeadFn}
          renderCCPaymentTableContentFn={renderCCPaymentTableContentFn}
        />
      ) : (
        <></>
      )}
      {cancelPopup ? (
        <Cancelpopup
          transactionId={transactionId}
          setFunction={setCancelPopup}
          badRequest={setBadRequest}
          error={setErrorPopup}
          verified={setCheckCancelled}
        />
      ) : (
        <></>
      )}
      {/* {footer?<CCFooter fetchCreditcardSearchData={fetchCreditcardSearchData}/>:<></>} */}
      {errorPopup ? <ErrorPopup setFunction={setErrorPopup} /> : <></>}
      {badRequest ? <BadRequestPopup setFunction={setBadRequest} /> : <></>}
      {checkCancelled ? (
        <VerifiedPopup
          message={'Successfully Cancelled Chargeback'}
          setFunction={setCheckCancelled}
          setFooter={setFooter}
          isCancel={true}
          fetchCreditcardSearchData={fetchCreditcardSearchData}
        />
      ) : (
        <></>
      )}
      {/* <RACModalCard
        className={classes.loaderModalCard}
        isOpen={loader}
        closeIcon={false}
        maxWidth={'xs'}
        borderRadius={'25px !important'}
        TitleClassName={`${classes.titlealign}`}
      >
        {
          <Grid className={`${classes.titlealign}`}>
            <CircularProgress />
          </Grid>
        }
      </RACModalCard> */}
    </Grid>
  );
}
