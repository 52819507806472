/*eslint-disable*/
import {
  CardContent,
  CircularProgress,
  Grid,
  RACTable,
} from '@rentacenter/racstrap';
import { Card } from '@rentacenter/racstrap';
import { CreditCardContext } from '../CreditcardContext';
import React, { useContext } from 'react';
import ErrorContent from '../CreditcardSearch/ErrorMsgContent';
import { getCCPaymentSummary } from '../../../api/user';
import { buildPayload } from './Commonfunctions';
import InfiniteScroll from 'react-infinite-scroll-component';
export default function CCsearchgrid(props: any) {
  const {
    classes,
    loader,
    pageDisplay,
    hasMore,
    setHasMore,
    setViewableRecords,
    displaySearch,
    viewableRecords,
    handleCharge,
    fetchDataLoader,
    setfetchDataLoader,sethandleCharge
  } = useContext(CreditCardContext);
  const renderErrorContent = (content: any, status: any) => (
    <ErrorContent content={content} status={status} style={classes.mt3} />
  );
  const request = buildPayload(handleCharge);
  const fetchNextData = async () => {
    try {
      setHasMore(true);
      setfetchDataLoader(true);
      let fetchedData: any = await getCCPaymentSummary(request);
      if (fetchedData?.status === 200) {
        sethandleCharge({...handleCharge,offset:handleCharge.offset + 20})
        setfetchDataLoader(false);
        if(fetchedData?.data){
          fetchedData.data = fetchedData?.data?.response ? [] : fetchedData?.data;
        }
        setViewableRecords((pre: any) => [...pre, ...fetchedData?.data]);
        if (fetchedData?.data?.length !== 20) {
          sethandleCharge({...handleCharge,offset:0})
          setHasMore(false);
        }
      } else {
        sethandleCharge({...handleCharge,offset:0})
        setHasMore(false);
      }
    } catch (e: any) {
      sethandleCharge({...handleCharge,offset:0})
      setfetchDataLoader(false);
      setHasMore(false);
    }
  };
  const renderTable = () => (
    <>
      {viewableRecords?.length > 9 ? (
        <InfiniteScroll
          next={fetchNextData}
          dataLength={viewableRecords?.length}
          hasMore={hasMore}
          height={displaySearch ? '65vh' : viewableRecords?.length >= 20 ? '83vh' : 'auto'}
          loader={
            fetchDataLoader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            className={`${classes.textFixHead}`}
            renderTableHead={props?.renderCCPaymentTableHeadFn}
            renderTableContent={props?.renderCCPaymentTableContentFn}
            stickyHeader
          />
        </InfiniteScroll>
      ) : (
        <RACTable
        className={`${classes.textFixHead}`}
        renderTableHead={props?.renderCCPaymentTableHeadFn}
        renderTableContent={props?.renderCCPaymentTableContentFn}
        stickyHeader
        />
      )}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={displaySearch === true ? `${classes.mt3}` : ''}
    >
      {loader ? (
        <Card className={classes.errorInfoCard} style={{ paddingLeft: '2%' }}>
          <CardContent>
            <CircularProgress />
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.card} style={{ paddingLeft: '2%' }}>
          <CardContent>
            <Grid className={classes.fixTableHeadAgrTrsRec}>
              {pageDisplay === 204 ? (
                renderErrorContent('No Records Found', 204)
              ) : pageDisplay === 200 ? (
                renderTable()
              ) : pageDisplay == 400 ? (
                renderErrorContent('Bad Request', 400)
              ) : pageDisplay == 500 ? (
                renderErrorContent('Something Went Wrong', 500)
              ) : (
                <></>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
}
