import React, { useContext } from 'react';
import { CreditCardContext } from '../CreditcardContext';
import { Grid, Typography } from '@rentacenter/racstrap';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export function CustomerDetails() {
  const { customer, loader } = useContext(CreditCardContext);
  const classes = EmilyGlobalStyles();
  const history = useHistory();
  const getLoader = () => (
    <Typography style={{ width: '75%' }}>
      <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
    </Typography>
  );
  const navigatingData = () => (
    <>
      <Grid container md={12} className={classes.colr}>
        <Grid item md={6}>
          <Typography>Store #</Typography>
        </Grid>
        <Grid item md={6}>
          {loader ? (
            getLoader()
          ) : (
            <Typography
              className={`${classes.racpadLink}`}
              onClick={() => {
                if (customer?.storeNumber) {
                  history.push({
                    pathname: `/operations/store/${customer?.storeNumber}`,
                  });
                }
              }}
            >
              {customer?.storeNumber ? customer.storeNumber : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.colr}>
        <Grid item md={6}>
          <Typography>Customer Name</Typography>
        </Grid>
        <Grid item md={6}>
          {loader ? (
            getLoader()
          ) : (
            <Typography
              variant="body2"
              className={`${classes.racpadLink}`}
              onClick={() => {
                if (customer?.customerName) {
                  history.push({
                    pathname: `/operations/customer/details/${customer?.customerId}`,
                  });
                }
              }}
            >
              {customer?.customerName ? customer.customerName : '-'}{' '}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.colr}>
        <Grid item md={6}>
          <Typography>Address 1</Typography>
        </Grid>
        <Grid item md={6} className={classes.formValue}>
          {loader ? (
            getLoader()
          ) : (
            <Typography>
              {customer?.addressLine1 ? customer.addressLine1 : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Grid container>
        <Grid container md={12} style={{ marginBottom: '1rem' }}>
          <Typography variant="h5">Customer Information</Typography>
        </Grid>
        {navigatingData()}
        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography>Address 2</Typography>
          </Grid>
          <Grid item md={6}>
            {loader ? (
              getLoader()
            ) : (
              <Typography
                className={`${classes.formValue} ${classes.pr3}`}
                noWrap
              >
                {customer?.addressLine2 ? customer.addressLine2 : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography>City</Typography>
          </Grid>
          <Grid item md={6}>
            {loader ? (
              getLoader()
            ) : (
              <Typography className={classes.formValue}>
                {customer?.city ? customer.city : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography>State</Typography>
          </Grid>
          <Grid item md={6}>
            {loader ? (
              getLoader()
            ) : (
              <Typography className={classes.formValue}>
                {customer?.state ? customer.state : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography>Zip</Typography>
          </Grid>
          <Grid item md={6}>
            {loader ? (
              getLoader()
            ) : (
              <Typography className={classes.formValue}>
                {customer?.postalCode ? customer.postalCode : '-'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
