import React from 'react';
import { Grid, Typography } from '@rentacenter/racstrap';
import { useParams } from 'react-router-dom';
import { params } from '../../../interface';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';

export function DetailsHeader() {
  const classes = EmilyGlobalStyles();
  const params = useParams<params>();
  return (
    <>
      <Grid container md={12} style={{ paddingBottom: '1rem' }}>
        <Grid item md={11} sm={10} xs={9} lg={11}>
          <Typography
            className={`${classes.title1}`}
            style={{ marginTop: '1rem' }}
          >
            {params.category == 'addchargeback'
              ? 'Add Chargeback'
              : params.category == 'editchargeback'
              ? 'Edit Chargeback'
              : 'View Chargeback'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
