import {
  Grid,
  RACButton,
  RACModalCard,
  RACSelect,
  Typography,
} from '@rentacenter/racstrap';
import { ReturnCheckStyles } from '../../JsStyles/ReturnCheckStyles';
import React, { useContext, useState, useEffect } from 'react';
import { CreditCardContext } from './CreditcardContext';
import { manageCCDetails } from '../../api/user';
import { LoadingScreen } from '../SharedComponents/LoadingScreen';
import { useParams } from 'react-router-dom';

export function Cancelpopup(props?: any) {
  const classes = ReturnCheckStyles();
  const { dropdownLoader, dropReasons, cancelReason, setCancelReason } =
    useContext(CreditCardContext);
  useEffect(() => {
    setCancelReason('');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loader, setLoader] = useState<boolean>(false);
  const routePath: any = useParams();

  const saveCancellation = async () => {
    try {
      const transaction = routePath.transactionId
        ? routePath.transactionId
        : props?.transactionId;
      setLoader(true);
      const cancelResponse: any = await manageCCDetails({
        transactionId: transaction,
        cancellationReason: cancelReason,
      });
      setLoader(false);
      props?.setFunction(false);
      if (
        cancelResponse &&
        cancelResponse?.status == 200 &&
        cancelResponse.data.value.transactionId
      ) {
        props?.verified(true);
      } else if (cancelResponse && cancelResponse?.status == 400) {
        props?.badRequest(true);
      } else {
        props?.error(true);
      }
    } catch (e: any) {
      props?.setFunction(false);
      props?.badRequest(true);
      props?.error(true);
    }
  };

  return (
    <>
      {loader ? <LoadingScreen /> : <></>}

      <RACModalCard
        isOpen={true}
        closeIcon={true}
        onClose={() => {
          props?.setFunction(false);
        }}
        title=" Chargeback Cancellation"
        maxWidth={'xs'}
        borderRadius={'15px'}
      >
        <Grid container md={12}>
          <Grid item md={12} style={{ padding: '0px 12px' }}>
            <Typography variant="h6">
              Why is this Chargeback being cancelled?
            </Typography>
          </Grid>
          <Grid item md={7} style={{ padding: '10px 12px' }}>
            <RACSelect
              defaultValue={cancelReason}
              onChange={(e: any) => {
                setCancelReason(e.target.value);
              }}
              options={dropReasons}
              loading={dropdownLoader}
            />
          </Grid>
        </Grid>

        <Grid style={{ marginTop: '30px', padding: '0px 20px' }}>
          <Grid className={`${classes.floatRight} `}>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                saveCancellation();
              }}
              disabled={cancelReason === '' ? true : false}
            >
              Save
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="outlined"
              color="secondary"
              className={`${classes.mr3}`}
              style={{ padding: '10px 25px' }}
              onClick={() => {
                props?.setFunction(false);
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </RACModalCard>
    </>
  );
}
