import React, { useContext } from 'react';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import { Grid, Typography } from '@rentacenter/racstrap';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

export function CustomerInfo() {
  const { classes, checkDetails, loadDetails } = useContext(ReturnCheckContext);
  const history = useHistory();

  const getLoader = () => (
    <Typography className={classes.w75}>
      <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
    </Typography>
  );

  const navigatingData = () => (
    <>
      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Store #</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography
              variant="body2"
              className={`${classes.racpadLink}`}
              onClick={() => {
                if (checkDetails?.storeNumber) {
                  history.push({
                    pathname: `/operations/store/${checkDetails?.storeNumber}`,
                  });
                }
              }}
            >
              {checkDetails?.storeNumber ? checkDetails?.storeNumber : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Customer Name</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography
              variant="body2"
              className={`${classes.racpadLink}`}
              onClick={() => {
                if (checkDetails?.customerId) {
                  history.push({
                    pathname: `/operations/customer/details/${checkDetails?.customerId}`,
                  });
                }
              }}
            >
              {checkDetails?.customerName ? checkDetails?.customerName : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container md={12} className={classes.subTitle}>
        <Typography variant="h5">Customer Information</Typography>
      </Grid>

      {navigatingData()}

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Address 1</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.addressLine1 ? checkDetails?.addressLine1 : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Address 2</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography className={`${classes.pr3}`} variant="body2">
              {checkDetails?.addressLine2 ? checkDetails?.addressLine2 : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>City</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.city ? checkDetails?.city : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>State</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.state ? checkDetails?.state : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container md={12} className={classes.cardValue}>
        <Grid item md={4}>
          <Typography>Zip</Typography>
        </Grid>
        <Grid item md={8}>
          {loadDetails ? (
            getLoader()
          ) : (
            <Typography variant="body2">
              {checkDetails?.zip ? checkDetails?.zip : '-'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}
