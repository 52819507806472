import React, { useState, createContext } from 'react';
import { ReturnCheckStyles } from '../../JsStyles/ReturnCheckStyles';
import {
  PaymentInfo,
  Searchparams,
  dropdownValues,
} from '../../Interface/ReturnCheckInterface';
import { DEFAULT_CHECK_LIMIT } from '../../constants/constants';

export const ReturnCheckContext = createContext<any>('');

export function ReturnCheckContextProvider(props: any) {
  const classes = ReturnCheckStyles();
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [dropdownLoader, setDropdownLoader] = useState<boolean>(true);
  const [searchStatus, setSearchStatus] = useState<number>(0);
  const [loadDetails, setLoadDetails] = useState<boolean>(true);
  const [checkDetails, setCheckDetails] = useState({});
  const [returnedFee, setReturnedFee] = useState<string>('');
  const [saveCheck, setSaveCheck] = useState<boolean>(false);
  const [filteredChecks, setFilteredChecks] = useState<any[]>([]);
  const [cancelReason, setCancelReason] = useState<string>('');

  const [searchParams, setSearchParams] = useState<Searchparams>({
    storeNumber: '',
    amount: '',
    checkNumber: '',
    fromDate: '',
    toDate: '',
    checkWrittenBy: '',
    status: 'ALL',
    offset: 0,
    limit: DEFAULT_CHECK_LIMIT,
  });

  const [searchRequest, setSearchRequest] = useState<Searchparams>({
    storeNumber: '',
    amount: '',
    checkNumber: '',
    fromDate: '',
    toDate: '',
    checkWrittenBy: '',
    status: '',
    offset: DEFAULT_CHECK_LIMIT,
    limit: DEFAULT_CHECK_LIMIT,
  });

  const [addReasons, setAddReasons] = useState<dropdownValues[]>([
    { label: 'Select', value: '' },
  ]);

  const [dropReasons, setDropReasons] = useState<dropdownValues[]>([
    { label: 'Select', value: '' },
  ]);

  const [paymentRequest, setPaymentRequest] = useState<PaymentInfo>({
    transactionId: '',
    returnedDate: '',
    returnedReason: '',
    returnedAmount: '',
  });
  const [reloadData, setReloadData] = useState<boolean>(false);

  return (
    <div>
      <ReturnCheckContext.Provider
        value={{
          classes,
          loadDetails,
          setLoadDetails,
          searchStatus,
          setSearchStatus,
          checkDetails,
          setCheckDetails,
          paymentRequest,
          setPaymentRequest,
          returnedFee,
          setReturnedFee,
          filteredChecks,
          setFilteredChecks,
          saveCheck,
          setSaveCheck,
          addReasons,
          setAddReasons,
          dropReasons,
          setDropReasons,
          showFilter,
          setShowFilter,
          dropdownLoader,
          setDropdownLoader,
          cancelReason,
          setCancelReason,
          searchParams,
          setSearchParams,
          searchRequest,
          setSearchRequest,
          reloadData,
          setReloadData,
        }}
      >
        {props.children}
      </ReturnCheckContext.Provider>
    </div>
  );
}
