/*eslint-disable*/
import { getCancelDropDown } from '../../../api/user';
const CANCEL_REASONS = ['CHERR', 'RVBK'];
const ADD_REASONS = [
  'PRECMPLNCE',
  'SRVCNOTRCD',
  'CANRCTXTN',
  'NOTASDESCD',
  'FRAMULDRFT',
  'CPYILLEGBL',
  'FRADMAILTX',
  'CNTRFTTXAN',
  'NOVERFFILE',
  'DECLNDAUTH',
  'NOAUTHZN',
  'EXPRDCARD',
  'LATEPRESNT',
  'CARDHDDNR',
  'INCRTTXNCD',
  'NOMACTACC',
  'INLGBLTXTN',
  'NONROFSD',
  'PROERRACCT',
  'FRDTXNPRST',
  'DUPPRESSNG',
  'FRDTRNCARD',
  'CRDNOTPRSD',
  'ALTEREDAMT',
  'NONROFMERC',
  'NONROFCASH',
  'RISKIDSERC',
  'TXNEXDLAMT',
];

export async function getCCDropdowns() {
  const reasonsResponse = await getCancelDropDown();

  if (
    reasonsResponse &&
    reasonsResponse.status == 200 &&
    reasonsResponse.data.AddCancelReason &&
    reasonsResponse.data.AddReturnReason
  ) {
    const cancelReason = [{ label: 'Select', value: '' }];
    const addReason = [{ label: 'Select', value: '' }];

    reasonsResponse.data.AddCancelReason.forEach((obj: any) => {
      if (obj.active == 'Y' && CANCEL_REASONS.includes(obj.referenceCode)) {
        cancelReason.push({ label: obj.description, value: obj.referenceCode });
      }
    });

    reasonsResponse.data.AddReturnReason.forEach((obj: any) => {
      if (obj.active == 'Y' && ADD_REASONS.includes(obj.referenceCode)) {
        addReason.push({ label: obj.description, value: obj.referenceCode });
      }
    });

    return { returnReasons: addReason, cancelReasons: cancelReason };
  }
}
export const ChargebackHeadings = [
  { heading: 'Store #' },
  { heading: 'Payment Date' },
  { heading: 'Category' },
  { heading: 'Customer Name' },
  { heading: 'CC Last 4 Digit' },
  { heading: 'Auth #' },
  { heading: 'Amount' },
  { heading: 'Action' },
];
export function buildPayload(handleCharge: any) {
  const {
    storeNumber,
    status,
    authorizationNum,
    lastFourDigit,
    fromDate,
    toDate,
    amount,
    limit,
    offset
  } = handleCharge;
  return {
    authorizationNum: authorizationNum.trim() || null,
    lastFourDigit: lastFourDigit.trim() || null,
    storeNumber: storeNumber || null,
    fromDate: fromDate || null,
    toDate: toDate || null,
    amount: amount.trim() || null,
    status: status || null,
    offset,
    limit: limit || null,
  };
}

export function formatDate(dateString: any) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}
export function formateditDate(dateString: any) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}
export function formatePayment(amount: string | number) {
  const sanitizedAmount =
    typeof amount === 'string' ? amount.replace(/,/g, '') : amount;
  const parsedAmount = Number(sanitizedAmount);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const formattedValue = formatter.format(parsedAmount);
  return formattedValue.replace('$', '$ ');
}
export function maskCreditCard(cardNumber: string) {
  const asteriskCount = Math.max(0, 8);

  return '*'.repeat(asteriskCount) + cardNumber.slice(-4);
}
export function categoryType(refCode: string) {
  switch (refCode) {
    case 'CreditCard Chargeback': {
      return 'Credit Card Payment';
    }
    case 'Chargeback': {
      return 'Chargeback';
    }
    case 'Paid Chargeback': {
      return 'Paid Chargeback';
    }
  }
}
export function CCcategoryType(refCode: string) {
  switch (refCode) {
    case 'Creditcard Payment': {
      return 'Credit Card Payment';
    }
    case 'Chargeback': {
      return 'Chargeback';
    }
    case 'Paid Chargeback': {
      return 'Paid Chargeback';
    }
  }
}
export function validatesearch(handleCharge: any) {
  const conditions: any[] = [];
  if (handleCharge?.storeNumber?.trim()) {
    conditions.push((handleCharge?.storeNumber?.trim()?.length ?? 0) > 4);
  }

  if (handleCharge?.lastFourDigit?.trim()) {
    conditions.push(handleCharge?.lastFourDigit?.trim() !== '');
  }

  if (handleCharge?.authorizationNum?.trim()) {
    conditions.push(handleCharge?.authorizationNum?.trim() !== '');
  }

  if (handleCharge?.fromDate?.trim()) {
    conditions.push(handleCharge?.fromDate?.trim() !== '');
  }

  if (handleCharge?.toDate?.trim()) {
    conditions.push(handleCharge?.toDate?.trim() !== '');
  }
  if (handleCharge?.amount?.trim()) {
    conditions.push(handleCharge?.amount?.trim() !== '');
  }
  return conditions.length > 0 && conditions.every((condition) => condition);
}
