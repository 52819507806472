/* eslint-disable prettier/prettier */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const EmilyGlobalStyles = () => {
    const useClasses = makeStyles((theme) => ({
        '@global': {
          '*::-webkit-scrollbar': {
            width: '10px',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgb(189, 189, 196)',
            borderRadius: '20px',
            border: '3px solid rgb(206, 204, 201)',
          },
          '*::-webkit-scrollbar-track': {
            background: 'rgb(245, 243, 239)',
          
          },
        },
        totrec:{
          float:"left",
          marginTop:"20px"
        },
        storeCell:{textAlign: 'left', width: '11%'},
        storeCell1:{textAlign: 'left', width: '14%'},
        storeCell2:{textAlign: 'left', width: '15%'},
        storeCell2:{textAlign: 'left', width: '10%'},
        storeCell3:{textAlign: 'left', width: '8%' },
        width1:{width: '14%' },
        width2:{ width: '10%' },
        buttonStyle:{fontWeight: 'bold', padding: '7px', width: '6%'},
        buttonStyle1:{fontWeight: 'bold',
        padding: '7px',
        float: 'right',
        marginRight: '3px'},
        maincontainer:{
          backgroundColor:"#ECF0F3",
          padding:"0.5%",
          height:"100%"
        },
        pagePadding1: {
          padding: "0 1% 1% 1%",
          paddingTop: theme.typography.pxToRem(24),
          width: '100%',
          borderRadius: theme.typography.pxToRem(16),
        },    
        padding6: {
          padding: '10px 25px'
        },
    
        container:{
          backgroundColor:"#ECF0F3",
          padding:"0.5%",
        },
        title:{
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontWeight:"bold",
            fontSize: theme.typography.pxToRem(20),
            marginBottom: theme.typography.pxToRem(10),
            
        },
        marginleft:{marginLeft: '55%' },
        marginsleft:{marginLeft: '55%' },
        displayBlock:{display: 'block'},
        height:{height: '60px'},
        margin1:{marginTop: '10px' },
        grid1:{position: 'fixed', bottom: '0px', width: '100%'},
        gridClass:{            
          position: 'fixed',
        background: 'transparent',
        // background: "#f7f5f5",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 0.6,
        zIndex: 2000,
        textAlign: 'center',
        margin: '0px 0px'},
        gridprogress:{display: 'block',
        position: 'fixed',
        zIndex: 9999999,
        top: '40%',
        right: '50%',},
        textLeft:{
          textAlign: 'left'
        },
        Ctable:{
          marginTop: '1px',
          left: '9px',
          textAlign: 'center'
        },
        dollar:{
            padding:"6.8px"
        },
        dollar1:{
          padding:"4.8px"
        },
        currencyAlign:{
            textAlign:"right"
        },  
        bold: {
          fontFamily: 'OpenSans-Bold',
        }, 
        container: {
          width: '100%',
          height: '100vh',
          backgroundColor: '#ECF0F3',
        },     
        semiBold: {
          fontFamily: 'OpenSans-semibold',
          
        },
        card: {
          borderRadius: theme.typography.pxToRem(15),
          boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
          width: '100%'
        },
    
        cardheading:{
          marginTop: '25px'
        },
        customercard:{
          float:"left",
          height:"350px"
        },
        editbttn:{
          marginLeft: '20px', 
          width: '8px', 
          padding: '3px'
        },
        cancelbttn:{
          color:"#ff3333",
          marginLeft: '10px', 
          width: '8px', 
          padding: '3px'
        },
        inputstyle:{
          fontWeight:"bold",
          marginBottom:"38px"
        },
        typostyle:{
          marginBottom:"24px"
        },
        inputcard:{
          display:"inline",
          marginTop:"30px"
        },
        fixTableHeadAgrTrsRec: {
          overflowY: 'auto',
          '& th': {
            position: 'sticky',
            top: 0,
            backgroundColor: '#ffffff',
            fontFamily: 'OpenSans-bold',
            zIndex: 1,
            padding: '0.5rem 0.2rem',
            whiteSpace: 'nowrap',
            fontSize: '15px',
            fontWeight: '400',
            color: '#111111',    
          },
        },
        fixTableHeadAgrTrsRec1: {
          overflowY: 'hidden',
          '& th': {
            position: 'sticky',
            top: 0,
            backgroundColor: '#ffffff',
            fontFamily: 'OpenSans-bold',
            zIndex: 1,
            padding: '0.5rem 0.2rem',
            whiteSpace: 'nowrap',
            fontSize: '15px',
            fontWeight: '400',
            color: '#111111',
          },
        },
        boxcard:{marginTop:"25px"},
        mt2:{
          marginTop: theme.typography.pxToRem(10)
        },
        mt3:{
            marginTop: theme.typography.pxToRem(25),
        },
        GridLoader: {
          textAlign: 'center',
          marginBottom: theme.typography.pxToRem(20),
          fontSize: theme.typography.pxToRem(16),
          color: `${RACCOLOR.RICH_BLACK}`,
          margin: '30px 0px',
        }, 
        mb4: {
          marginBottom: theme.typography.pxToRem(10),
        },       
        mt5:{
          marginTop: theme.typography.pxToRem(48),
        },   
        mb3:{
            marginBottom: theme.typography.pxToRem(16),
        },
        titlealign:{
            textAlign:'center'
        },
        filterbttn:{
          padding: '8px 10px',
          marginLeft: '20px',
          marginBottom: '20px'
        },
        loaderModalCard: {
          '& div': {
            '& div': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        },
    
        clrbutton:{
          width:"90px",
          padding: '7px 11px',
          marginLeft: '20px',
          marginTop:"17px",
          marginBottom: '20px',
          float: 'right'
        },
        applybttn:{
          width:"90px",
          padding: '7px 11px',
          marginLeft: '20px',
          marginTop:"17px",
          float: 'right'
        },
          floatLeft: {
            float: 'left',
          },
          floatRight: {
            float: 'right',
          },
          sortimg:{
            marginTop: '5px', 
            marginLeft: '5px' 

          },
          sortimgstyle:{
            marginTop: '5px', 
            marginLeft: '5px' 
          },
          newCard: {
            height:"120px",marginTop: '1rem',marginBottom: '1rem'
        },
        textFixHead: {

          '& th': {

              top: 0,
              zIndex: 10,
              backgroundColor: '#ffffff',
              fontFamily: 'OpenSans-bold',
              padding: '0.5rem 0.2rem',
              fontSize: '15px',
              fontWeight: '400',
              color: '#111111',
          },

          '& tbody': {
              '& tr': {

                  backgroundColor: '#ffffff',
              },
              '& td': {
                  fontFamily: 'OpenSans-semibold',
                  padding: '0.9375rem 0.2rem',
              },
          },
      },
        errorInfoCard: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width:'100%',
          height: '200px',
          borderRadius: '15px'
      },
          currencyField: {
        '& input': {
          textAlign: 'end'
        }
      },dollarSymbol: {
        height: "32px"
      },
      fixedFooter: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: `${RACCOLOR.WHITE}`,
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        padding: '1rem',

    },
    colr:{
      margin: '0.5rem 0rem'
    },
    mr3: {
      marginRight: theme.typography.pxToRem(16),
  },
    errorbutton: {
      backgroundColor: "#ef5350",
      '&:hover': {
          backgroundColor: "#c62828"
      }
  },
      racpadLink: {
        color: '#2179FE',
        fontSize: theme.typography.pxToRem(14),
        textDecoration: 'none',
        cursor: 'pointer',
        fontFamily: 'OpenSans-semibold',
    },
      pr3: {
        paddingRight: theme.typography.pxToRem(16),
    },
      formValue: {
        fontFamily: 'OpenSans-semibold',
        letterSpacing: 'normal !important',
        color: 'darkslateblue',
        fontSize: '14px',
        // marginTop: '4px'
    },
          formLabel: {
            marginBottom: '0.2rem',
            color: '#000',
            marginTop:'3%',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
          },
          inputcard:{
            height:'95%',
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%'
          },
        card:{
            height:'100%',
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        textBlue:{
            color: `${RACCOLOR.NORMAL_BLUE}`,
        },
        semiBold:{
            fontFamily: 'OpenSans-semibold',
            
        },
        whitebackground:{
          backgroundColor:"white"
        },
        textDarkSlateBlue:{
          color:"DarkSlateBlue"
        },
        customModal2: {
            '& div': {
              '& div': {
                maxWidth: '650px',
                maxHeight:'850px'
              },
            },
          },
          customModal1: {
            '& div': {
              '& div': {
                maxWidth: '300px',
              },
            },
          },
          customModal3: {
            '& div': {
              '& div': {
                maxWidth: '450px'
              },
            },
          },
          pagination:{
            display:'flex',
            listStyle:"none",
            textDecoration:"none",
            fontFamily:"OpenSans-semibold",
            listStyleType:"none",
            fontSize:theme.typography.pxToRem(14),
            justifyContent:'flex-end',
            cursor:'pointer'

          },
          pageLink:{
            position:'relative',
            display:'block',
            textDecoration:'none',
            padding:'5px 0px',
            width:'29px',
            textAlign:'center'
          },
          paginationPrevNext:{
            color:"#000",
            padding:'5px 17px',
            display:'inline-block'
          },
          paginationPrevNextDisabled:{
            color:'lightgray !important',
            padding:'5px 17px',
            display:'inline-block'
          },
          pageActive:{
            backgroundColor:'#2468FF',
            color:'#fff',
            borderRadius:'50%'
          },
          cursorPointer: {
            cursor: 'pointer',
          },
          modalcardpadding:{
            padding:'0px 6px'
          },
          mR1:{
            marginRight: '10px',
          },
          title1: {
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontWeight: "bold",
            fontSize: '18px',
            marginBottom: theme.typography.pxToRem(10),
          },
      
          mL1: {
            marginLeft: '10px',
          },
          mt9: {
            marginTop: theme.typography.pxToRem(8)
          },      
          txtbox:{
            width:"10px",
            padding:'0px 0px'
          },

        successCard: {
      '& div': {
        '& div': {
          maxWidth: "350px"
        },
      }
    },
    alertCardContent: {
      '& div': {
        marginBottom: '1rem',
        justifyContent: "center"
      },
    },
          cancelcontainer:{
            height: '120px', 
            paddingLeft: '10px'
          },
          // cancelbuttonstyle:{
          //   marginLeft: '55%',
          //   float:'right',
          // },
          headingchargeback:{
            fontWeight:"bold",
            marginTop:'25px'
          },
          okbuttonstyle:{
            fontWeight:"bold",
            padding:"5px",
            width:"50px"
          },
          marginleft:{
            marginLeft:"3%"
          },
          cancelbuttonstyle:{
            padding: '10px 5px',
            width: '80px',
            color: 'gray',
            border: '1px solid gray'
          },
          cancelchrg:{
            fontWeight:"bold",
            padding:"7px",
            float:'right',
            marginRight:"7px",
            backgroundColor:"#d24434",
            color:'white'
          },
          clrbutton:{
            marginTop:"17px",
            width:"90px",
            padding: '7px 11px',
            float:"right"

          },
          Savedropdownbutton:{
            padding: '10px 5px', 
            width: '80px',
            marginLeft: '4%'
          },
          pb4: {
            paddingBottom: theme.typography.pxToRem(24),
          },
          mx1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
          },
}))
    const classes = useClasses();
    return classes;    
}
