import {
  Grid,
  RACDatePicker,
  RACSelect,
  RACTextbox,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import { CreditCardContext } from '../CreditcardContext';
import { formatePayment } from '../CreditcardSearch/Commonfunctions';
import { useParams } from 'react-router-dom';
import { params } from '../../../interface';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export function CCDetailsInputs() {
  const classes = EmilyGlobalStyles();
  const params: params = useParams();
  const [view, setView] = useState<boolean>(false);
  const {
    dropdownLoader,
    customer,
    loader,
    addReasons,
    setSaveCheck,
    updateData,
    setupdateDate,
  } = useContext(CreditCardContext);
  useEffect(() => {
    if (params.category == 'viewchargeback') {
      setView(true);
    }
    setSaveCheck(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getBalanceAmount = () => {
    const payment = customer?.paymentAmount;
    const charged = updateData?.chargeBackAmount;
    const amount: number = Number(payment) - Number(charged?.replace(/,/g, ''));
    return formatePayment(amount);
  };
  return (
    <>
      <Grid container>
        <Grid container md={12} style={{ marginBottom: '2rem' }}></Grid>

        <Grid container md={12} className={classes.colr}>
          <Grid item md={6} style={{ marginTop: '5px' }}>
            <Typography>Chargeback Date</Typography>
          </Grid>
          <Grid item md={6} className={classes.formValue}>
            <RACDatePicker
              label={''}
              disabled={view}
              onChange={(e) => {
                setSaveCheck(true);
                setupdateDate({ ...updateData, chargeBackDate: e });
              }}
              value={updateData.chargeBackDate}
            />
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography style={{ marginTop: '5px' }}>
              Chargeback Reason
            </Typography>
          </Grid>
          <Grid item md={6}>
            <RACSelect
              options={addReasons}
              isDisabled={view}
              loading={dropdownLoader}
              onChange={(e) => {
                setSaveCheck(true);
                setupdateDate({
                  ...updateData,
                  chargeBackReason: e.target.value,
                });
              }}
              defaultValue={updateData.chargeBackReason}
            />
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.colr}>
          <Grid item md={6}>
            <Typography style={{ marginTop: '5px' }}>
              Charge back Amount
            </Typography>
          </Grid>
          <Grid item md={6}>
            <RACTextbox
              isCurrency={true}
              disabled={view}
              value={updateData.chargeBackAmount}
              dollarTextClassName={`${classes.dollar}`}
              className={classes.currencyField}
              OnChange={(e) => {
                setSaveCheck(true);
                const onlyLetters = /^[0-9,'.']+$/;
                if (!onlyLetters.test(e.target.value)) {
                  e.target.value = e.target.value.replace(/[^0-9,'.']/g, '');
                }
                {
                  setupdateDate({
                    ...updateData,
                    chargeBackAmount: e.target.value,
                  });
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid container md={12} style={{ margin: '0.8rem 0rem' }}>
          <Grid item md={6}>
            <Typography>Balance Due</Typography>
          </Grid>
          <Grid item md={6}>
            {loader ? (
              <Typography style={{ width: '75%' }}>
                <Skeleton
                  count={1}
                  baseColor="#FFFFFF"
                  highlightColor="#e7e7e7"
                />
              </Typography>
            ) : (
              <Typography className={classes.formValue}>
                {params.category == 'editchargeback'
                  ? getBalanceAmount()
                  : '$ 0.00'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
