import React, { useContext, useEffect, useState } from 'react';
import { ReturnCheckContext } from '../../context/ReturnCheck/ReturnCheckContext';
import { getCheckPaymentDetails } from '../../api/user';
import { useParams } from 'react-router-dom';
import { getCheckDropdowns } from '../../utils/ReturnCheckUtils';
import { DetailsHeader } from './DetailsComponents/DetailsHeader';
import { DetailsCard } from './DetailsComponents/DetailsCard';
import { Footer } from './DetailsComponents/Footer';
import ErrorPopup from '../SharedComponents/ErrorPopup';
import BadRequestPopup from '../SharedComponents/BadRequestPopup';

export function CheckDetails() {
  const {
    classes,
    setAddReasons,
    setDropReasons,
    setDropdownLoader,
    addReasons,
    dropReasons,
    setPaymentRequest,
    setCheckDetails,
    setReturnedFee,
    setLoadDetails,
  } = useContext(ReturnCheckContext);

  const routePath: any = useParams();
  const [badRequest, setBadRequest] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);

  const getCheckDetails = async () => {
    try {
      setLoadDetails(true);

      const detailsResponse = await getCheckPaymentDetails(
        routePath.transactionId
      );

      if (
        detailsResponse &&
        detailsResponse?.status == 200 &&
        detailsResponse?.data &&
        detailsResponse?.data?.checkDetail
      ) {
        setCheckDetails(detailsResponse.data.checkDetail[0]);
        setPaymentRequest({
          transactionId: routePath?.transactionId,
          returnedDate: detailsResponse?.data?.checkDetail[0]?.returnedDate,
          returnedReason:
            routePath.category == 'add'
              ? ''
              : routePath.category == 'add'
              ? ''
              : detailsResponse?.data.checkDetail[0]?.returnedReason,
          returnedAmount: detailsResponse?.data?.checkDetail[0]?.returnedAmount
            ? detailsResponse?.data?.checkDetail[0]?.returnedAmount.toString()
            : '',
        });
        setReturnedFee(detailsResponse?.data?.returnedFee);
      } else if (detailsResponse && detailsResponse?.status == 400) {
        setBadRequest(true);
      } else {
        setErrorPopup(true);
      }
      setLoadDetails(false);
    } catch (e: any) {
      setBadRequest(false);
      setErrorPopup(true);
    }
  };

  const bindDropDowns = async () => {
    try {
      setDropdownLoader(true);
      const dropdownvalues = await getCheckDropdowns();
      setDropdownLoader(false);
      setAddReasons(dropdownvalues?.returnReasons);
      setDropReasons(dropdownvalues?.cancelReasons);
    } catch (e: any) {
      setDropdownLoader(false);
      setErrorPopup(true);
    }
  };

  const resetData = () => {
    setReturnedFee('');
    setPaymentRequest({
      transactionId: '',
      returnedDate: '',
      returnedReason: '',
      returnedAmount: '',
    });
    setCheckDetails({});
  };

  useEffect(() => {
    resetData();
    getCheckDetails();
    if (addReasons?.length == 1 && dropReasons?.length == 1) {
      bindDropDowns();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.p1}>
        <DetailsHeader />
        <DetailsCard />
      </div>
      <Footer />

      {errorPopup ? <ErrorPopup /> : <></>}
      {badRequest ? <BadRequestPopup /> : <></>}
    </>
  );
}
