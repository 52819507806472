import {
  Grid,
  RACDatePicker,
  RACSelect,
  RACTextbox,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect } from 'react';
import { ReturnCheckContext } from '../../../context/ReturnCheck/ReturnCheckContext';
import { formatCurrency, formatYearby } from '../../../utils/ReturnCheckUtils';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

export function PaymentDetails() {
  const {
    classes,
    dropdownLoader,
    addReasons,
    setSaveCheck,
    paymentRequest,
    setPaymentRequest,
    returnedFee,
    loadDetails,
  } = useContext(ReturnCheckContext);
  const routePath: any = useParams();

  const getReturnedAmount = () => {
    const returnedAmount = paymentRequest?.returnedAmount;
    const amount: number =
      Number(returnedAmount) + Number(returnedFee?.replace(/,/g, ''));
    return formatCurrency(amount);
  };
  const getLoader = () => (
    <Typography className={classes.w75}>
      <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
    </Typography>
  );

  useEffect(() => {
    setSaveCheck(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container>
        <Grid container md={12} className={classes.mbr2}></Grid>

        <Grid container md={12} className={classes.m05}>
          <Grid item md={4} className={classes.mt5}>
            <Typography>Returned Date</Typography>
          </Grid>
          <Grid item md={6} className={classes.formValue}>
            <RACDatePicker
              label={''}
              value={
                paymentRequest?.returnedDate
                  ? formatYearby(paymentRequest?.returnedDate)
                  : ''
              }
              disabled={
                routePath?.category == 'paidonreturncheck' ||
                routePath?.category == 'paidinreturncheck'
                  ? true
                  : false
              }
              onChange={(e: any) => {
                setSaveCheck(true);
                setPaymentRequest({ ...paymentRequest, returnedDate: e });
              }}
            />
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.m05}>
          <Grid item md={4}>
            <Typography className={classes.mt5}>Returned Reason</Typography>
          </Grid>
          <Grid item md={6}>
            <RACSelect
              name="retunReason"
              defaultValue={
                paymentRequest?.returnedReason
                  ? paymentRequest?.returnedReason
                  : ''
              }
              onChange={(e: any) => {
                setSaveCheck(true);
                setPaymentRequest({
                  ...paymentRequest,
                  returnedReason: e?.target?.value,
                });
              }}
              isDisabled={
                routePath?.category == 'paidonreturncheck' ||
                routePath?.category == 'paidinreturncheck'
                  ? true
                  : false
              }
              loading={dropdownLoader}
              options={addReasons}
            />
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.m05}>
          <Grid item md={4}>
            <Typography className={classes.mt5}>Returned Amount</Typography>
          </Grid>
          <Grid item md={6}>
            <RACTextbox
              type="number"
              isCurrency={true}
              className={classes.currencyField}
              value={paymentRequest?.returnedAmount}
              maxlength={22}
              disabled={
                routePath?.category == 'paidonreturncheck' ||
                routePath?.category == 'paidinreturncheck'
                  ? true
                  : false
              }
              OnChange={(e: any) => {
                setSaveCheck(true);
                setPaymentRequest({
                  ...paymentRequest,
                  returnedAmount: e?.target?.value.replace(/,/g, ''),
                });
              }}
              dollarTextClassName={`${classes.dollarClass} ${classes.dollarSymbol}`}
            />
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.m08}>
          <Grid item md={4}>
            <Typography>Returned Fee</Typography>
          </Grid>
          <Grid item md={6}>
            {loadDetails ? (
              getLoader()
            ) : (
              <Typography variant="body2">
                {returnedFee ? formatCurrency(returnedFee) : '$ 0.00'}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container md={12} className={classes.m08}>
          <Grid item md={4}>
            <Typography>Balance Due</Typography>
          </Grid>
          <Grid item md={6}>
            {loadDetails ? (
              getLoader()
            ) : (
              <Typography variant="body2">
                {routePath?.category == 'edit' ? getReturnedAmount() : '$ 0.00'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
