import { Grid, Typography } from '@rentacenter/racstrap';
import { ReactComponent as NoRecords } from '../../../Assets/No records.svg';
import { ReactComponent as AlertIcon } from '../../../Assets/no-records-found.svg';
import { EmilyGlobalStyles } from '../../../Styles/emilystyles';
import React from 'react';
export default function ErrorContent(props: any) {
  const classes = EmilyGlobalStyles();
  return (
    <Grid container md={12} className={`${classes.newCard}`}>
      <Grid item md={12} className={`${classes.titlealign}`}>
        <Typography
          variant="body2"
          className={`${classes.mb4} ${props?.style}`}
        >
          {props?.status == 204 ? <NoRecords /> : <AlertIcon />}
        </Typography>
        <Typography variant="body1">{props?.content}</Typography>
      </Grid>
    </Grid>
  );
}
